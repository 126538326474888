// Source: https://pl.wikipedia.org/wiki/ISO_3166-1
export const polishCountryNames: Record<string, string> = {
  AF: "Afganistan",
  AX: "Wyspy Alandzkie",
  AL: "Albania",
  DZ: "Algieria",
  AS: "Samoa Amerykańskie",
  AD: "Andora",
  AO: "Angola",
  AI: "Anguilla",
  AQ: "Antarktyka",
  AG: "Antigua i Barbuda",
  AR: "Argentyna",
  AM: "Armenia",
  AW: "Aruba",
  AU: "Australia",
  AT: "Austria",
  AZ: "Azerbejdżan",
  BS: "Bahamy",
  BH: "Bahrajn",
  BD: "Bangladesz",
  BB: "Barbados",
  BY: "Białoruś",
  BE: "Belgia",
  BZ: "Belize",
  BJ: "Benin",
  BM: "Bermudy",
  BT: "Bhutan",
  BO: "Boliwia",
  BQ: "Bonaire, Sint Eustatius i Saba",
  BA: "Bośnia i Hercegowina",
  BW: "Botswana",
  BV: "Wyspa Bouveta",
  BR: "Brazylia",
  IO: "Brytyjskie Terytorium Oceanu Indyjskiego",
  BN: "Brunei",
  BG: "Bułgaria",
  BF: "Burkina Faso",
  BI: "Burundi",
  CV: "Republika Zielonego Przylądka",
  KH: "Kambodża",
  CM: "Kamerun",
  CA: "Kanada",
  KY: "Kajmany",
  CF: "Republika Środkowoafrykańska",
  TD: "Czad",
  CL: "Chile",
  CN: "Chiny",
  CX: "Wyspa Bożego Narodzenia",
  CC: "Wyspy Kokosowe",
  CO: "Kolumbia",
  KM: "Komory",
  CD: "Demokratyczna Republika Konga",
  CG: "Kongo",
  CK: "Wyspy Cooka",
  CR: "Kostaryka",
  HR: "Chorwacja",
  CU: "Kuba",
  CW: "Curaçao",
  CY: "Cypr",
  CZ: "Czechy",
  DK: "Dania",
  DJ: "Dżibuti",
  DM: "Dominika",
  DO: "Dominikana",
  EC: "Ekwador",
  EG: "Egipt",
  SV: "Salwador",
  GQ: "Gwinea Równikowa",
  ER: "Erytrea",
  EE: "Estonia",
  SZ: "Eswatini",
  ET: "Etiopia",
  FK: "Falklandy",
  FO: "Wyspy Owcze",
  FJ: "Fidżi",
  FI: "Finlandia",
  FR: "Francja",
  GF: "Gujana Francuska",
  PF: "Polinezja Francuska",
  TF: "Francuskie Terytoria Południowe i Antarktyczne",
  GA: "Gabon",
  GM: "Gambia",
  GE: "Gruzja",
  DE: "Niemcy",
  GH: "Ghana",
  GI: "Gibraltar",
  GR: "Grecja",
  GL: "Grenlandia",
  GD: "Grenada",
  GP: "Gwadelupa",
  GU: "Guam",
  GT: "Gwatemala",
  GG: "Guernsey",
  GN: "Gwinea",
  GW: "Gwinea Bissau",
  GY: "Gujana",
  HT: "Haiti",
  HM: "Wyspy Heard i McDonalda",
  VA: "Watykan",
  HN: "Honduras",
  HU: "Węgry",
  IS: "Islandia",
  IN: "Indie",
  ID: "Indonezja",
  IR: "Iran",
  IQ: "Irak",
  IE: "Irlandia",
  IM: "Wyspa Man",
  IL: "Izrael",
  IT: "Włochy",
  CI: "Wybrzeże Kości Słoniowej",
  JM: "Jamajka",
  JP: "Japonia",
  JE: "Jersey",
  JO: "Jordania",
  KZ: "Kazachstan",
  KE: "Kenia",
  KI: "Kiribati",
  KP: "Korea Północna",
  KR: "Korea Południowa",
  KW: "Kuwejt",
  KG: "Kirgistan",
  LA: "Laos",
  LV: "Łotwa",
  LB: "Liban",
  LS: "Lesotho",
  LR: "Liberia",
  LY: "Libia",
  LI: "Liechtenstein",
  LT: "Litwa",
  LU: "Luksemburg",
  MG: "Madagaskar",
  MW: "Malawi",
  MY: "Malezja",
  MV: "Malediwy",
  ML: "Mali",
  MT: "Malta",
  MH: "Wyspy Marshalla",
  MQ: "Martynika",
  MR: "Mauretania",
  MU: "Mauritius",
  YT: "Majotta",
  MX: "Meksyk",
  FM: "Mikronezja",
  MD: "Mołdawia",
  MC: "Monako",
  MN: "Mongolia",
  ME: "Czarnogóra",
  MS: "Montserrat",
  MA: "Maroko",
  MZ: "Mozambik",
  MM: "Mjanma",
  NA: "Namibia",
  NR: "Nauru",
  NP: "Nepal",
  NL: "Holandia",
  NC: "Nowa Kaledonia",
  NZ: "Nowa Zelandia",
  NI: "Nikaragua",
  NE: "Niger",
  NG: "Nigeria",
  NU: "Niue",
  NF: "Norfolk",
  MK: "Macedonia Północna",
  MP: "Mariany Północne",
  NO: "Norwegia",
  OM: "Oman",
  PK: "Pakistan",
  PW: "Palau",
  PS: "Palestyna",
  PA: "Panama",
  PG: "Papua-Nowa Gwinea",
  PY: "Paragwaj",
  PE: "Peru",
  PH: "Filipiny",
  PN: "Pitcairn",
  PL: "Polska",
  PT: "Portugalia",
  PR: "Portoryko",
  QA: "Katar",
  RE: "Reunion",
  RO: "Rumunia",
  RU: "Rosja",
  RW: "Rwanda",
  BL: "Saint-Barthélemy",
  SH: "Wyspa Świętej Heleny, Wniebowstąpienia i Tristan da Cunha",
  KN: "Saint Kitts i Nevis",
  LC: "Saint Lucia",
  MF: "Saint-Martin",
  PM: "Saint-Pierre i Miquelon",
  VC: "Saint Vincent i Grenadyny",
  WS: "Samoa",
  SM: "San Marino",
  ST: "Wyspy Świętego Tomasza i Książęca",
  SA: "Arabia Saudyjska",
  SN: "Senegal",
  RS: "Serbia",
  SC: "Seszele",
  SL: "Sierra Leone",
  SX: "Sint Maarten",
  SK: "Słowacja",
  SI: "Słowenia",
  SB: "Wyspy Salomona",
  SO: "Somalia",
  ZA: "Południowa Afryka",
  GS: "Georgia Południowa i Sandwich Południowy",
  SS: "Sudan Południowy",
  ES: "Hiszpania",
  LK: "Sri Lanka",
  SD: "Sudan",
  SR: "Surinam",
  SJ: "Svalbard i Jan Mayen",
  SE: "Szwecja",
  CH: "Szwajcaria",
  SY: "Syria",
  TW: "Tajwan",
  TJ: "Tadżykistan",
  TZ: "Tanzania",
  TH: "Tajlandia",
  TL: "Timor Wschodni",
  TG: "Togo",
  TK: "Tokelau",
  TO: "Tonga",
  TT: "Trynidad i Tobago",
  TN: "Tunezja",
  TR: "Turcja",
  TM: "Turkmenistan",
  TC: "Turks i Caicos",
  TV: "Tuvalu",
  UG: "Uganda",
  UA: "Ukraina",
  AE: "Zjednoczone Emiraty Arabskie",
  GB: "Wielka Brytania",
  UM: "Dalekie Wyspy Mniejsze Stanów Zjednoczonych",
  US: "Stany Zjednoczone",
  UY: "Urugwaj",
  UZ: "Uzbekistan",
  VU: "Vanuatu",
  VE: "Wenezuela",
  VN: "Wietnam",
  VG: "Brytyjskie Wyspy Dziewicze",
  VI: "Wyspy Dziewicze Stanów Zjednoczonych",
  WF: "Wallis i Futuna",
  EH: "Sahara Zachodnia",
  YE: "Jemen",
  ZM: "Zambia",
  ZW: "Zimbabwe",

  // Country codes not part of ISO-3166-1 (reserved, deleted or unassigned):
  AN: "Antyle Holenderskie",
  GZ: "Strefa Gazy",
  XK: "Kosowo",
};
