// Source:
// Countries with long/lat => https://developers.google.com/public-data/docs/canonical/countries_csv
// Countries images => https://github.com/djaiss/mapsicon
// Country area => https://github.com/samayo/country-json/blob/master/src/country-by-surface-area.json
// Cities : https://public.opendatasoft.com/explore/dataset/geonames-all-cities-with-a-population-1000/information/?flg=fr&disjunctive.cou_name_en&sort=name

import { areas } from "./countries.area";
import { countries } from "./countries.position";
import { frenchCountryNames } from "./countries.name.fr";
import { hungarianCountryNames } from "./countries.name.hu";
import { dutchCountryNames } from "./countries.name.nl";
import { polishCountryNames } from "./countries.name.pl";
import { japaneseCountryNames } from "./countries.name.ja";
import { countryCodesWithImage } from "./countries.image";

export interface Country {
  code: string;
  latitude: number;
  longitude: number;
  name: string;
}

export interface City {
  name: string;
  country: string;
  population: number;
}

export const cities: City[] = [
  { name: "Canillo", country: "ad", population: 3292 },
  { name: "Ordino", country: "ad", population: 3066 },
  { name: "Arinsal", country: "ad", population: 1419 },
  { name: "la Massana", country: "ad", population: 7211 },
  { name: "Sant Julià de Lòria", country: "ad", population: 8022 },
  { name: "Encamp", country: "ad", population: 11223 },
  { name: "El Tarter", country: "ad", population: 1052 },
  { name: "Pas de la Casa", country: "ad", population: 2363 },
  { name: "Andorra la Vella", country: "ad", population: 20430 },
  { name: "les Escaldes", country: "ad", population: 15853 },
  { name: "Zayed City", country: "ae", population: 63482 },
  { name: "Adh Dhayd", country: "ae", population: 24716 },
  { name: "Khalifah A City", country: "ae", population: 85374 },
  { name: "Maşfūţ", country: "ae", population: 8988 },
  { name: "Dibba Al-Hisn", country: "ae", population: 26395 },
  { name: "Musaffah", country: "ae", population: 243341 },
  { name: "Ar Ruways", country: "ae", population: 16000 },
  { name: "Al Manāmah", country: "ae", population: 5823 },
  { name: "Dubai", country: "ae", population: 2956587 },
  { name: "Abu Dhabi", country: "ae", population: 603492 },
  { name: "Reef Al Fujairah City", country: "ae", population: 82310 },
  { name: "Ras Al Khaimah City", country: "ae", population: 351943 },
  { name: "Imām Şāḩib", country: "af", population: 9659 },
  { name: "Ashkāsham", country: "af", population: 12120 },
  { name: "Qala i Naw", country: "af", population: 9000 },
  { name: "Uruzgān", country: "af", population: 13388 },
  { name: "Ḩukūmatī Dahanah-ye Ghōrī", country: "af", population: 3307 },
  { name: "Chīras", country: "af", population: 12779 },
  { name: "Darzāb", country: "af", population: 9642 },
  { name: "Karukh", country: "af", population: 17484 },
  { name: "Sharan", country: "af", population: 2200 },
  { name: "Qarchī Gak", country: "af", population: 8942 },
  { name: "Shibirghān", country: "af", population: 55641 },
  { name: "Qalāt", country: "af", population: 12191 },
  { name: "Potters Village", country: "ag", population: 1433 },
  { name: "All Saints", country: "ag", population: 2526 },
  { name: "Saint John’s", country: "ag", population: 24226 },
  { name: "Piggotts", country: "ag", population: 1878 },
  { name: "Liberta", country: "ag", population: 1668 },
  { name: "Codrington", country: "ag", population: 1325 },
  { name: "Bolands", country: "ag", population: 1657 },
  { name: "The Valley", country: "ai", population: 2035 },
  { name: "Fushë-Arrëz", country: "al", population: 2438 },
  { name: "Roskovec", country: "al", population: 6657 },
  { name: "Peshkopi", country: "al", population: 14848 },
  { name: "Shijak", country: "al", population: 14138 },
  { name: "Patos", country: "al", population: 60000 },
  { name: "Durrës", country: "al", population: 122034 },
  { name: "Ballsh", country: "al", population: 10361 },
  { name: "Rrëshen", country: "al", population: 10064 },
  { name: "Pukë", country: "al", population: 6495 },
  { name: "Memaliaj", country: "al", population: 4951 },
  { name: "Përrenjas-Fshat", country: "al", population: 4015 },
  { name: "Këlcyrë", country: "al", population: 4900 },
  { name: "Zarr", country: "am", population: 1340 },
  { name: "Tandzut", country: "am", population: 1816 },
  { name: "Spitak", country: "am", population: 15059 },
  { name: "Khasht’arrak", country: "am", population: 1728 },
  { name: "Karanlukh", country: "am", population: 5104 },
  { name: "Janfida", country: "am", population: 2986 },
  { name: "Byurakan", country: "am", population: 3902 },
  { name: "Bambakashat", country: "am", population: 3089 },
  { name: "Sasunik", country: "am", population: 1959 },
  { name: "Goravan", country: "am", population: 2238 },
  { name: "Armash", country: "am", population: 2434 },
  { name: "Tsaghkadzor", country: "am", population: 1407 },
  { name: "Lumeji", country: "ao", population: 4972 },
  { name: "Huambo", country: "ao", population: 226145 },
  { name: "Saurimo", country: "ao", population: 40498 },
  { name: "Benguela", country: "ao", population: 151226 },
  { name: "Lobito", country: "ao", population: 207932 },
  { name: "Cuito", country: "ao", population: 113624 },
  { name: "Catabola", country: "ao", population: 18855 },
  { name: "Moçâmedes", country: "ao", population: 80149 },
  { name: "Malanje", country: "ao", population: 87046 },
  { name: "Camabatela", country: "ao", population: 12837 },
  { name: "Caconda", country: "ao", population: 10549 },
  { name: "Luena", country: "ao", population: 21115 },
  { name: "McMurdo Station", country: "aq", population: 1258 },
  { name: "Santa Ana", country: "ar", population: 2059 },
  { name: "Palmar Grande", country: "ar", population: 1672 },
  { name: "Oberá", country: "ar", population: 56528 },
  { name: "Morón", country: "ar", population: 319934 },
  { name: "Loreto", country: "ar", population: 1201 },
  { name: "La Plata", country: "ar", population: 694167 },
  { name: "Chavarría", country: "ar", population: 2337 },
  { name: "Buenos Aires", country: "ar", population: 13076300 },
  { name: "Azul", country: "ar", population: 53941 },
  { name: "Yuto", country: "ar", population: 7732 },
  { name: "Yacimiento Río Turbio", country: "ar", population: 6650 },
  { name: "Villa Mugueta", country: "ar", population: 2789 },
  { name: "Malaeimi", country: "as", population: 1261 },
  { name: "Fagatogo", country: "as", population: 1868 },
  { name: "Mapusagafou", country: "as", population: 2052 },
  { name: "Vaitogi", country: "as", population: 1436 },
  { name: "Faleniu", country: "as", population: 3200 },
  { name: "Aūa", country: "as", population: 2124 },
  { name: "Leone", country: "as", population: 4050 },
  { name: "Vailoatai", country: "as", population: 1144 },
  { name: "Pago Pago", country: "as", population: 11500 },
  { name: "Tāfuna", country: "as", population: 11017 },
  { name: "Zellerndorf", country: "at", population: 1144 },
  { name: "Wolfau", country: "at", population: 1436 },
  { name: "Traisen", country: "at", population: 3453 },
  { name: "Sulz", country: "at", population: 2527 },
  { name: "Schlitters", country: "at", population: 1481 },
  { name: "Sankt Ulrich bei Steyr", country: "at", population: 1379 },
  { name: "Sankt Jakob-Breitenau", country: "at", population: 1127 },
  { name: "Reichenau an der Rax", country: "at", population: 1276 },
  { name: "Radfeld", country: "at", population: 2495 },
  { name: "Prellenkirchen", country: "at", population: 1036 },
  { name: "Pill", country: "at", population: 1174 },
  { name: "Slade Point", country: "au", population: 3202 },
  { name: "Holloways Beach", country: "au", population: 2376 },
  { name: "Ultimo", country: "au", population: 8857 },
  { name: "Neutral Bay", country: "au", population: 10250 },
  { name: "Currambine", country: "au", population: 6771 },
  { name: "Chapel Hill", country: "au", population: 9866 },
  { name: "Fitzgibbon", country: "au", population: 5572 },
  { name: "Mermaid Beach", country: "au", population: 7410 },
  { name: "Churchill", country: "au", population: 4732 },
  { name: "Booral", country: "au", population: 1494 },
  { name: "Chain Valley Bay", country: "au", population: 2364 },
  { name: "Lennox Head", country: "au", population: 6315 },
  { name: "Santa Cruz", country: "aw", population: 12900 },
  { name: "Tanki Leendert", country: "aw", population: 21500 },
  { name: "Oranjestad", country: "aw", population: 29998 },
  { name: "San Nicolas", country: "aw", population: 15200 },
  { name: "Paradera", country: "aw", population: 12000 },
  { name: "Savaneta", country: "aw", population: 11500 },
  { name: "Mariehamn", country: "ax", population: 10682 },
  { name: "Hammarland", country: "ax", population: 1395 },
  { name: "Sund", country: "ax", population: 1080 },
  { name: "Jomala", country: "ax", population: 3662 },
  { name: "Eckerö", country: "ax", population: 1028 },
  { name: "Lemland", country: "ax", population: 1829 },
  { name: "Saltvik", country: "ax", population: 1691 },
  { name: "Finström", country: "ax", population: 2412 },
  { name: "Zabrat", country: "az", population: 21396 },
  { name: "Qusar", country: "az", population: 16022 },
  { name: "Lokbatan", country: "az", population: 30694 },
  { name: "Şahbuz", country: "az", population: 3127 },
  { name: "Culfa", country: "az", population: 12500 },
  { name: "Vank", country: "az", population: 1335 },
  { name: "Kyadabek", country: "az", population: 8657 },
  { name: "Dolyar", country: "az", population: 4399 },
  { name: "Qabaqçöl", country: "az", population: 1293 },
  { name: "Badamdar", country: "az", population: 11398 },
  { name: "Haftoni", country: "az", population: 2003 },
  { name: "Beylagan", country: "az", population: 15599 },
  { name: "Polje-Bijela", country: "ba", population: 1078 },
  { name: "Krupa na Vrbasu", country: "ba", population: 1257 },
  { name: "Kakanj", country: "ba", population: 12406 },
  { name: "Bihać", country: "ba", population: 75641 },
  { name: "Balatun", country: "ba", population: 1400 },
  { name: "Knežica", country: "ba", population: 4792 },
  { name: "Jablanica", country: "ba", population: 6209 },
  { name: "Zborište", country: "ba", population: 4922 },
  { name: "Sokolac", country: "ba", population: 8842 },
  { name: "Sanica", country: "ba", population: 5666 },
  { name: "Grude", country: "ba", population: 4627 },
  { name: "Dubrave Donje", country: "ba", population: 3811 },
  { name: "Speightstown", country: "bb", population: 3634 },
  { name: "Bathsheba", country: "bb", population: 1765 },
  { name: "Oistins", country: "bb", population: 2285 },
  { name: "Holetown", country: "bb", population: 1350 },
  { name: "Bridgetown", country: "bb", population: 98511 },
  { name: "Kālīganj", country: "bd", population: 45631 },
  { name: "Pālang", country: "bd", population: 67652 },
  { name: "Pār Naogaon", country: "bd", population: 192464 },
  { name: "Jahedpur", country: "bd", population: 3000 },
  { name: "Shāhzādpur", country: "bd", population: 102420 },
  { name: "Comilla", country: "bd", population: 389411 },
  { name: "Sonārgaon", country: "bd", population: 130000 },
  { name: "Farīdpur", country: "bd", population: 112187 },
  { name: "Azimpur", country: "bd", population: 96641 },
  { name: "Joymontop", country: "bd", population: 56000 },
  { name: "Shibganj", country: "bd", population: 35961 },
  { name: "Bagerhat", country: "bd", population: 266388 },
  { name: "Fontaine-Valmont", country: "be", population: 1071 },
  { name: "Flémalle-Haute", country: "be", population: 25144 },
  { name: "Esen", country: "be", population: 3152 },
  { name: "Engis", country: "be", population: 5737 },
  { name: "Elsenborn", country: "be", population: 1965 },
  { name: "Ellikom", country: "be", population: 1183 },
  { name: "Écaussinnes-Lalaing", country: "be", population: 1119 },
  { name: "Durnal", country: "be", population: 1102 },
  { name: "De Pinte", country: "be", population: 10020 },
  { name: "Couthuin", country: "be", population: 2826 },
  { name: "Buvrinnes", country: "be", population: 1955 },
  { name: "Bever", country: "be", population: 1932 },
  { name: "Kombissiri", country: "bf", population: 30137 },
  { name: "Fada N'gourma", country: "bf", population: 33910 },
  { name: "Nouna", country: "bf", population: 29048 },
  { name: "Djibo", country: "bf", population: 22223 },
  { name: "Zorgo", country: "bf", population: 23892 },
  { name: "Gorom-Gorom", country: "bf", population: 6691 },
  { name: "Dédougou", country: "bf", population: 45341 },
  { name: "Dano", country: "bf", population: 11153 },
  { name: "Tougan", country: "bf", population: 17590 },
  { name: "Pô", country: "bf", population: 17924 },
  { name: "Goulouré", country: "bf", population: 6677 },
  { name: "Bobo-Dioulasso", country: "bf", population: 360106 },
  { name: "Zavet", country: "bg", population: 3589 },
  { name: "Sofia", country: "bg", population: 1152556 },
  { name: "Shabla", country: "bg", population: 3901 },
  { name: "Senovo", country: "bg", population: 1459 },
  { name: "Loznitsa", country: "bg", population: 2971 },
  { name: "Gotse Delchev", country: "bg", population: 20522 },
  { name: "Dunavtsi", country: "bg", population: 3371 },
  { name: "Bolyarovo", country: "bg", population: 1368 },
  { name: "Bansko", country: "bg", population: 9232 },
  { name: "Vratsa", country: "bg", population: 64941 },
  { name: "Slivnitsa", country: "bg", population: 7545 },
  { name: "Sandanski", country: "bg", population: 28510 },
  { name: "Al Muharraq", country: "bh", population: 97458 },
  { name: "Madīnat ‘Īsá", country: "bh", population: 38090 },
  { name: "Ar Rifā‘", country: "bh", population: 79550 },
  { name: "Madīnat Ḩamad", country: "bh", population: 52718 },
  { name: "Manama", country: "bh", population: 147074 },
  { name: "Al Ḩadd", country: "bh", population: 12797 },
  { name: "Sitrah", country: "bh", population: 37657 },
  { name: "Dār Kulayb", country: "bh", population: 65466 },
  { name: "Jidd Ḩafş", country: "bh", population: 31735 },
  { name: "Karuzi", country: "bi", population: 10705 },
  { name: "Kirundo", country: "bi", population: 6083 },
  { name: "Mwaro", country: "bi", population: 4924 },
  { name: "Bujumbura", country: "bi", population: 331700 },
  { name: "Muyinga", country: "bi", population: 71076 },
  { name: "Kayanza", country: "bi", population: 19443 },
  { name: "Makamba", country: "bi", population: 19642 },
  { name: "Gitega", country: "bi", population: 41000 },
  { name: "Cankuzo", country: "bi", population: 6585 },
  { name: "Muramvya", country: "bi", population: 18041 },
  { name: "Rutana", country: "bi", population: 20893 },
  { name: "Bubanza", country: "bi", population: 12728 },
  { name: "Grand-Popo", country: "bj", population: 9847 },
  { name: "Tchaourou", country: "bj", population: 20971 },
  { name: "Pobé", country: "bj", population: 32983 },
  { name: "Cotonou", country: "bj", population: 780000 },
  { name: "Bembèrèkè", country: "bj", population: 24006 },
  { name: "Guilmaro", country: "bj", population: 6516 },
  { name: "Bétérou", country: "bj", population: 13108 },
  { name: "Aplahoué", country: "bj", population: 19862 },
  { name: "Savalou", country: "bj", population: 30187 },
  { name: "Kandi", country: "bj", population: 109701 },
  { name: "Sakété", country: "bj", population: 30111 },
  { name: "Nikki", country: "bj", population: 69970 },
  { name: "Gustavia", country: "bl", population: 5988 },
  { name: "Saint George", country: "bm", population: 1896 },
  { name: "Kuala Belait", country: "bn", population: 31178 },
  { name: "Mentiri", country: "bn", population: 1121 },
  { name: "Bandar Seri Begawan", country: "bn", population: 64409 },
  { name: "Kapok", country: "bn", population: 4337 },
  { name: "Bangar", country: "bn", population: 3970 },
  { name: "Tutong", country: "bn", population: 19151 },
  { name: "Seria", country: "bn", population: 30097 },
  { name: "San Ignacio de Velasco", country: "bo", population: 23569 },
  { name: "Sacaba", country: "bo", population: 180726 },
  { name: "Rurrenabaque", country: "bo", population: 11749 },
  { name: "Entre Ríos", country: "bo", population: 2685 },
  { name: "Colcapirhua", country: "bo", population: 52732 },
  { name: "Betanzos", country: "bo", population: 4847 },
  { name: "Cobija", country: "bo", population: 26585 },
  { name: "Ascención de Guarayos", country: "bo", population: 18816 },
  { name: "Trinidad", country: "bo", population: 84259 },
  { name: "Colchani", country: "bo", population: 1074 },
  { name: "Santa Ana de Yacuma", country: "bo", population: 12783 },
  { name: "Punata", country: "bo", population: 15194 },
  { name: "Oranjestad", country: "bq", population: 1097 },
  { name: "Dorp Tera Kora", country: "bq", population: 1186 },
  { name: "Dorp Rincón", country: "bq", population: 1496 },
  { name: "Upper Hell's Gate", country: "bq", population: 2810 },
  { name: "Dorp Antriol", country: "bq", population: 2980 },
  { name: "Kralendijk", country: "bq", population: 3081 },
  { name: "Vitorino Freire", country: "br", population: 13267 },
  { name: "Terra Santa", country: "br", population: 13512 },
  { name: "Santo Antônio do Tauá", country: "br", population: 8921 },
  { name: "Santana", country: "br", population: 83927 },
  { name: "Porto Franco", country: "br", population: 14873 },
  { name: "Pombos", country: "br", population: 15894 },
  { name: "Orobó", country: "br", population: 5884 },
  { name: "Nazaré da Mata", country: "br", population: 26485 },
  { name: "Monção", country: "br", population: 11050 },
  { name: "Mazagão", country: "br", population: 6500 },
  { name: "Luzilândia", country: "br", population: 12999 },
  { name: "Girau do Ponciano", country: "br", population: 9606 },
  { name: "Cooper’s Town", country: "bs", population: 8413 },
  { name: "Arthur’s Town", country: "bs", population: 1175 },
  { name: "Andros Town", country: "bs", population: 2455 },
  { name: "High Rock", country: "bs", population: 3744 },
  { name: "Clarence Town", country: "bs", population: 1752 },
  { name: "Spanish Wells", country: "bs", population: 1500 },
  { name: "Nassau", country: "bs", population: 227940 },
  { name: "Freeport", country: "bs", population: 26910 },
  { name: "West End", country: "bs", population: 12724 },
  { name: "San Andros", country: "bs", population: 7800 },
  { name: "Marsh Harbour", country: "bs", population: 5314 },
  { name: "George Town", country: "bs", population: 7314 },
  { name: "Daga", country: "bt", population: 2243 },
  { name: "Sarpang", country: "bt", population: 10416 },
  { name: "Pajo", country: "bt", population: 3032 },
  { name: "Daphu", country: "bt", population: 1666 },
  { name: "Samdrup Jongkhar", country: "bt", population: 7507 },
  { name: "Wangdue Phodrang", country: "bt", population: 7507 },
  { name: "Ha", country: "bt", population: 1449 },
  { name: "Samtse", country: "bt", population: 5479 },
  { name: "Pemagatshel", country: "bt", population: 13864 },
  { name: "Paro", country: "bt", population: 2169 },
  { name: "Tsirang", country: "bt", population: 18667 },
  { name: "Laya", country: "bt", population: 2000 },
  { name: "Tsau", country: "bw", population: 1409 },
  { name: "Ramokgonami", country: "bw", population: 3681 },
  { name: "Gabane", country: "bw", population: 12884 },
  { name: "Serowe", country: "bw", population: 47419 },
  { name: "Otse", country: "bw", population: 6275 },
  { name: "Makobeng", country: "bw", population: 1822 },
  { name: "Mmathubudukwane", country: "bw", population: 2221 },
  { name: "Gobojango", country: "bw", population: 1756 },
  { name: "Ratholo", country: "bw", population: 2377 },
  { name: "Palapye", country: "bw", population: 30650 },
  { name: "Mopipi", country: "bw", population: 3301 },
  { name: "Moijabana", country: "bw", population: 2768 },
  { name: "Smilavičy", country: "by", population: 5200 },
  { name: "Shumilina", country: "by", population: 7500 },
  { name: "Syanno", country: "by", population: 7092 },
  { name: "Myshkavichy", country: "by", population: 2000 },
  { name: "Myazhysyatki", country: "by", population: 1807 },
  { name: "Krasnosel’skiy", country: "by", population: 7300 },
  { name: "Iwye", country: "by", population: 9684 },
  { name: "Ivatsevichy", country: "by", population: 24200 },
  { name: "Hotsk", country: "by", population: 1100 },
  { name: "Haradok", country: "by", population: 13380 },
  { name: "Brahin", country: "by", population: 3900 },
  { name: "Antopal’", country: "by", population: 2100 },
  { name: "San Antonio", country: "bz", population: 1204 },
  { name: "San Pedro", country: "bz", population: 17429 },
  { name: "Carmelita", country: "bz", population: 1474 },
  { name: "Punta Gorda", country: "bz", population: 5205 },
  { name: "Esperanza", country: "bz", population: 1262 },
  { name: "Valley of Peace", country: "bz", population: 1809 },
  { name: "Little Belize", country: "bz", population: 2830 },
  { name: "Belmopan", country: "bz", population: 13381 },
  { name: "Benque Viejo el Carmen", country: "bz", population: 6684 },
  { name: "San Jose", country: "bz", population: 2862 },
  { name: "San Narciso", country: "bz", population: 2422 },
  { name: "Progresso", country: "bz", population: 1357 },
  { name: "Ange-Gardien", country: "ca", population: 2420 },
  { name: "Beausejour", country: "ca", population: 3126 },
  { name: "Burford", country: "ca", population: 1615 },
  { name: "Cookshire", country: "ca", population: 1021 },
  { name: "Delson", country: "ca", population: 7322 },
  { name: "Gaspé", country: "ca", population: 14819 },
  { name: "Humboldt", country: "ca", population: 4822 },
  { name: "Huntingdon", country: "ca", population: 2865 },
  { name: "Jasper Park Lodge", country: "ca", population: 3907 },
  { name: "Killam", country: "ca", population: 1056 },
  { name: "Kincardine", country: "ca", population: 6725 },
  { name: "Kitchener", country: "ca", population: 233700 },
  { name: "Bolobo", country: "cd", population: 27862 },
  { name: "Lubumbashi", country: "cd", population: 1373770 },
  { name: "Kamina", country: "cd", population: 73557 },
  { name: "Kabalo", country: "cd", population: 29833 },
  { name: "Lusambo", country: "cd", population: 41416 },
  { name: "Kindu", country: "cd", population: 135698 },
  { name: "Kananga", country: "cd", population: 463546 },
  { name: "Kambove", country: "cd", population: 36702 },
  { name: "Mushie", country: "cd", population: 33062 },
  { name: "Moanda", country: "cd", population: 50000 },
  { name: "Kongolo", country: "cd", population: 31943 },
  { name: "Kisangani", country: "cd", population: 539158 },
  { name: "Bossangoa", country: "cf", population: 27428 },
  { name: "Boali", country: "cf", population: 5876 },
  { name: "Kembé", country: "cf", population: 11513 },
  { name: "Bimbo", country: "cf", population: 129655 },
  { name: "Ouadda", country: "cf", population: 5434 },
  { name: "Obo", country: "cf", population: 12887 },
  { name: "Birao", country: "cf", population: 10178 },
  { name: "Zemio", country: "cf", population: 14000 },
  { name: "Alindao", country: "cf", population: 14234 },
  { name: "Nola", country: "cf", population: 26809 },
  { name: "Bozoum", country: "cf", population: 40201 },
  { name: "Mongoumba", country: "cf", population: 10885 },
  { name: "Sibiti", country: "cg", population: 19089 },
  { name: "Makoua", country: "cg", population: 11355 },
  { name: "Djambala", country: "cg", population: 9650 },
  { name: "Ewo", country: "cg", population: 4923 },
  { name: "Kayes", country: "cg", population: 58737 },
  { name: "Mossendjo", country: "cg", population: 18231 },
  { name: "Dolisie", country: "cg", population: 103894 },
  { name: "Loandjili", country: "cg", population: 23204 },
  { name: "Gamboma", country: "cg", population: 20877 },
  { name: "Kinkala", country: "cg", population: 13882 },
  { name: "Impfondo", country: "cg", population: 20859 },
  { name: "Sémbé", country: "cg", population: 3113 },
  { name: "Wynau", country: "ch", population: 1610 },
  { name: "Wauwil", country: "ch", population: 1657 },
  { name: "Wängi", country: "ch", population: 4051 },
  { name: "Wetzikon / Unter-Wetzikon", country: "ch", population: 2521 },
  { name: "Turtmann", country: "ch", population: 1025 },
  { name: "Trachselwald", country: "ch", population: 1068 },
  { name: "Tafers", country: "ch", population: 2833 },
  { name: "Suhr", country: "ch", population: 9374 },
  { name: "Stalden", country: "ch", population: 1232 },
  { name: "Sankt Niklaus", country: "ch", population: 2352 },
  { name: "Reiden", country: "ch", population: 3926 },
  { name: "Puidoux", country: "ch", population: 2398 },
  { name: "Touba", country: "ci", population: 27504 },
  { name: "Bonoua", country: "ci", population: 37312 },
  { name: "Anyama", country: "ci", population: 100653 },
  { name: "Tiassalé", country: "ci", population: 35090 },
  { name: "Man", country: "ci", population: 139341 },
  { name: "Katiola", country: "ci", population: 59641 },
  { name: "Danané", country: "ci", population: 53808 },
  { name: "Vavoua", country: "ci", population: 31250 },
  { name: "Boundiali", country: "ci", population: 38878 },
  { name: "Bouaflé", country: "ci", population: 60962 },
  { name: "San-Pédro", country: "ci", population: 196751 },
  { name: "Mankono", country: "ci", population: 19095 },
  { name: "Avarua", country: "ck", population: 13373 },
  { name: "Taltal", country: "cl", population: 10018 },
  { name: "Talagante", country: "cl", population: 51764 },
  { name: "Coyhaique", country: "cl", population: 45787 },
  { name: "Vicuña", country: "cl", population: 13496 },
  { name: "Temuco", country: "cl", population: 238129 },
  { name: "Pitrufquén", country: "cl", population: 13743 },
  { name: "Panguipulli", country: "cl", population: 16312 },
  { name: "Paine", country: "cl", population: 32766 },
  { name: "La Junta", country: "cl", population: 1200 },
  { name: "Villarrica", country: "cl", population: 31602 },
  { name: "Freire", country: "cl", population: 7813 },
  { name: "Chimbarongo", country: "cl", population: 17356 },
  { name: "Okola", country: "cm", population: 5390 },
  { name: "Somié", country: "cm", population: 2000 },
  { name: "Edéa", country: "cm", population: 203149 },
  { name: "Bandjoun", country: "cm", population: 6872 },
  { name: "Ngoro", country: "cm", population: 2305 },
  { name: "Ndikiniméki", country: "cm", population: 8247 },
  { name: "Mbalmayo", country: "cm", population: 80206 },
  { name: "Makary", country: "cm", population: 7331 },
  { name: "Dizangué", country: "cm", population: 19243 },
  { name: "Bogo", country: "cm", population: 16952 },
  { name: "Tcholliré", country: "cm", population: 23187 },
  { name: "Nanga Eboko", country: "cm", population: 29909 },
  { name: "Damoujia", country: "cn", population: 47569 },
  { name: "Shanwang", country: "cn", population: 63195 },
  { name: "Katang Ailieke", country: "cn", population: 8011 },
  { name: "Yingqiu", country: "cn", population: 92564 },
  { name: "Leidong", country: "cn", population: 9188 },
  { name: "Donghe", country: "cn", population: 14771 },
  { name: "Lichuan", country: "cn", population: 12554 },
  { name: "Yanjia", country: "cn", population: 8325 },
  { name: "Ka'erqin", country: "cn", population: 10297 },
  { name: "Zhangfeng", country: "cn", population: 53370 },
  { name: "Shigu", country: "cn", population: 21006 },
  { name: "Heshun", country: "cn", population: 6560 },
  { name: "Vigía del Fuerte", country: "co", population: 4638 },
  { name: "Vergara", country: "co", population: 2267 },
  { name: "Topaipí", country: "co", population: 1099 },
  { name: "Tocancipá", country: "co", population: 6027 },
  { name: "Tibaná", country: "co", population: 1802 },
  { name: "Sonsón", country: "co", population: 17232 },
  { name: "Silos", country: "co", population: 1300 },
  { name: "Santana", country: "co", population: 2091 },
  { name: "San Pablo de Borbur", country: "co", population: 1519 },
  { name: "San Marcos", country: "co", population: 36549 },
  { name: "San Juan de Urabá", country: "co", population: 10825 },
  { name: "San Cayetano", country: "co", population: 1430 },
  { name: "Zarcero", country: "cr", population: 3559 },
  { name: "La Fortuna", country: "cr", population: 1774 },
  { name: "La Fortuna", country: "cr", population: 3010 },
  { name: "Cot", country: "cr", population: 6784 },
  { name: "Belén", country: "cr", population: 2715 },
  { name: "Santiago", country: "cr", population: 8292 },
  { name: "San Rafael", country: "cr", population: 8887 },
  { name: "Naranjo", country: "cr", population: 11853 },
  { name: "Guácimo", country: "cr", population: 7022 },
  { name: "Atenas", country: "cr", population: 7014 },
  { name: "Turrialba", country: "cr", population: 28955 },
  { name: "Sardinal", country: "cr", population: 3267 },
  { name: "Viñales", country: "cu", population: 27129 },
  { name: "Vertientes", country: "cu", population: 29704 },
  { name: "Unión de Reyes", country: "cu", population: 33646 },
  { name: "San Miguel del Padrón", country: "cu", population: 159273 },
  { name: "Morón", country: "cu", population: 66060 },
  { name: "Esperanza", country: "cu", population: 10462 },
  { name: "Corralillo", country: "cu", population: 51881 },
  { name: "Zaza del Medio", country: "cu", population: 8667 },
  { name: "Santo Domingo", country: "cu", population: 45476 },
  { name: "Ranchuelo", country: "cu", population: 43695 },
  { name: "Pinar del Río", country: "cu", population: 186990 },
  { name: "Manatí", country: "cu", population: 13278 },
  { name: "Praia", country: "cv", population: 113364 },
  { name: "Picos", country: "cv", population: 3778 },
  { name: "Tarrafal de São Nicolau", country: "cv", population: 5039 },
  { name: "Assomada", country: "cv", population: 7927 },
  { name: "São Domingos", country: "cv", population: 1850 },
  { name: "Pombas", country: "cv", population: 1818 },
  { name: "Santa Cruz", country: "cv", population: 9488 },
  { name: "Ponta do Sol", country: "cv", population: 4064 },
  { name: "Pedra Badejo", country: "cv", population: 9343 },
  { name: "Nova Sintra", country: "cv", population: 1813 },
  { name: "Ribeira Brava", country: "cv", population: 5324 },
  { name: "Tarrafal", country: "cv", population: 6463 },
  { name: "Willemstad", country: "cw", population: 125000 },
  { name: "Barber", country: "cw", population: 2424 },
  { name: "Sint Michiel Liber", country: "cw", population: 5138 },
  { name: "Dorp Soto", country: "cw", population: 2233 },
  { name: "Meneou", country: "cy", population: 1248 },
  { name: "Ágios Týchon", country: "cy", population: 2204 },
  { name: "Kokkinotrimithiá", country: "cy", population: 3223 },
  { name: "Nicosia", country: "cy", population: 200452 },
  { name: "Tríkomo", country: "cy", population: 4490 },
  { name: "Psimolofou", country: "cy", population: 1686 },
  { name: "Pérgamos", country: "cy", population: 15000 },
  { name: "Mesógi", country: "cy", population: 1689 },
  { name: "Derýneia", country: "cy", population: 5161 },
  { name: "Tála", country: "cy", population: 1655 },
  { name: "Péra", country: "cy", population: 1060 },
  { name: "Erími", country: "cy", population: 1493 },
  { name: "Žulová", country: "cz", population: 1332 },
  { name: "Zbýšov", country: "cz", population: 3890 },
  { name: "Zákupy", country: "cz", population: 2792 },
  { name: "Zaječí", country: "cz", population: 1435 },
  { name: "Vyškov", country: "cz", population: 22265 },
  { name: "Veřovice", country: "cz", population: 1936 },
  { name: "Vamberk", country: "cz", population: 4768 },
  { name: "Valdice", country: "cz", population: 1474 },
  { name: "Troubsko", country: "cz", population: 1822 },
  { name: "Trmice", country: "cz", population: 2862 },
  { name: "Třebechovice pod Orebem", country: "cz", population: 5611 },
  { name: "Terezín", country: "cz", population: 2992 },
  { name: "Zscherben", country: "de", population: 1600 },
  { name: "Zeuthen", country: "de", population: 10084 },
  { name: "Zehdenick", country: "de", population: 14809 },
  { name: "Wrestedt", country: "de", population: 2996 },
  { name: "Wolfschlugen", country: "de", population: 6108 },
  { name: "Wittislingen", country: "de", population: 2360 },
  { name: "Wittenhagen", country: "de", population: 1358 },
  { name: "Winden", country: "de", population: 1054 },
  { name: "Wilster", country: "de", population: 4443 },
  { name: "Wilgartswiesen", country: "de", population: 1164 },
  { name: "Wiesentheid", country: "de", population: 4759 },
  { name: "Wiedensahl", country: "de", population: 1061 },
  { name: "Dorra", country: "dj", population: 1873 },
  { name: "Goubétto", country: "dj", population: 2003 },
  { name: "Alaïli Ḏaḏḏa‘", country: "dj", population: 1456 },
  { name: "Loyada", country: "dj", population: 1646 },
  { name: "Arta", country: "dj", population: 6025 },
  { name: "'Ali Sabieh", country: "dj", population: 55000 },
  { name: "Holhol", country: "dj", population: 3519 },
  { name: "Obock", country: "dj", population: 17776 },
  { name: "Gâlâfi", country: "dj", population: 1849 },
  { name: "Dikhil", country: "dj", population: 12043 },
  { name: "Tadjourah", country: "dj", population: 22193 },
  { name: "Vestbjerg", country: "dk", population: 2873 },
  { name: "Ulfborg", country: "dk", population: 1994 },
  { name: "Tørring", country: "dk", population: 2370 },
  { name: "Skive", country: "dk", population: 20815 },
  { name: "Nørre Åby", country: "dk", population: 2979 },
  { name: "Nivå", country: "dk", population: 7991 },
  { name: "Neder Holluf", country: "dk", population: 5612 },
  { name: "Maribo", country: "dk", population: 5349 },
  { name: "Malling", country: "dk", population: 6194 },
  { name: "Langeskov", country: "dk", population: 4152 },
  { name: "Kolt", country: "dk", population: 5061 },
  { name: "Haarby", country: "dk", population: 2400 },
  { name: "Pointe Michel", country: "dm", population: 1202 },
  { name: "Calibishie", country: "dm", population: 1020 },
  { name: "La Plaine", country: "dm", population: 1332 },
  { name: "Woodford Hill", country: "dm", population: 1063 },
  { name: "Salisbury", country: "dm", population: 1413 },
  { name: "Soufrière", country: "dm", population: 1416 },
  { name: "Roseau", country: "dm", population: 16571 },
  { name: "Portsmouth", country: "dm", population: 3633 },
  { name: "Mahaut", country: "dm", population: 2369 },
  { name: "Berekua", country: "dm", population: 2608 },
  { name: "Castle Bruce", country: "dm", population: 1387 },
  { name: "Saint Joseph", country: "dm", population: 2184 },
  { name: "Vallejuelo", country: "do", population: 5217 },
  { name: "Sabana Iglesia", country: "do", population: 5135 },
  { name: "Pueblo Viejo", country: "do", population: 4192 },
  { name: "Padre Las Casas", country: "do", population: 8485 },
  { name: "Juncalito Abajo", country: "do", population: 1229 },
  { name: "Jicomé", country: "do", population: 1725 },
  { name: "Cristóbal", country: "do", population: 2735 },
  { name: "Villa Riva", country: "do", population: 5700 },
  { name: "San José de Las Matas", country: "do", population: 9853 },
  { name: "Nizao", country: "do", population: 6530 },
  { name: "Hondo Valle", country: "do", population: 3713 },
  { name: "El Peñón", country: "do", population: 4088 },
  { name: "Salah Bey", country: "dz", population: 23339 },
  { name: "Remchi", country: "dz", population: 51224 },
  { name: "Lakhdaria", country: "dz", population: 56719 },
  { name: "Beni Mester", country: "dz", population: 19808 },
  { name: "Batna", country: "dz", population: 280798 },
  { name: "’Aïn el Hadjar", country: "dz", population: 12996 },
  { name: "Aïn Beïda", country: "dz", population: 105765 },
  { name: "Tipaza", country: "dz", population: 25225 },
  { name: "Oued Sly", country: "dz", population: 30504 },
  { name: "Hammamet", country: "dz", population: 28536 },
  { name: "El Meghaïer", country: "dz", population: 49793 },
  { name: "El Eulma", country: "dz", population: 128351 },
  { name: "Tulcán", country: "ec", population: 86498 },
  { name: "Santa Elena", country: "ec", population: 42214 },
  { name: "Samborondón", country: "ec", population: 24118 },
  { name: "Naranjal", country: "ec", population: 32045 },
  { name: "Machala", country: "ec", population: 198123 },
  { name: "Guayaquil", country: "ec", population: 1952029 },
  { name: "Bahía de Caráquez", country: "ec", population: 37056 },
  { name: "San Miguel de Salcedo", country: "ec", population: 10838 },
  { name: "San Miguel", country: "ec", population: 12575 },
  { name: "Quevedo", country: "ec", population: 119436 },
  { name: "Portovelo", country: "ec", population: 9708 },
  { name: "Cariamanga", country: "ec", population: 16862 },
  { name: "Vana-Antsla", country: "ee", population: 1468 },
  { name: "Puhja", country: "ee", population: 1072 },
  { name: "Laagri", country: "ee", population: 5165 },
  { name: "Vaida", country: "ee", population: 1016 },
  { name: "Tapa", country: "ee", population: 6551 },
  { name: "Narva", country: "ee", population: 66980 },
  { name: "Toila", country: "ee", population: 2304 },
  { name: "Rapla", country: "ee", population: 5684 },
  { name: "Paldiski", country: "ee", population: 4202 },
  { name: "Orissaare", country: "ee", population: 1065 },
  { name: "Kiili", country: "ee", population: 1461 },
  { name: "Kehtna", country: "ee", population: 1579 },
  { name: "Madīnat Sittah Uktūbar", country: "eg", population: 41930 },
  { name: "Awsīm", country: "eg", population: 63862 },
  { name: "Ismailia", country: "eg", population: 284813 },
  { name: "Hurghada", country: "eg", population: 95622 },
  { name: "Al Bawīţī", country: "eg", population: 20000 },
  { name: "Abū al Maţāmīr", country: "eg", population: 41302 },
  { name: "El Gouna", country: "eg", population: 15000 },
  { name: "Marsa Alam", country: "eg", population: 10000 },
  { name: "Dishnā", country: "eg", population: 54197 },
  { name: "Al Jammālīyah", country: "eg", population: 68381 },
  { name: "Kafr ash Shaykh", country: "eg", population: 143970 },
  { name: "Dayrūţ", country: "eg", population: 67788 },
  { name: "Teseney", country: "er", population: 3753 },
  { name: "Edd", country: "er", population: 11259 },
  { name: "Asmara", country: "er", population: 563930 },
  { name: "Dek’emhāre", country: "er", population: 10959 },
  { name: "Ak’ordat", country: "er", population: 8857 },
  { name: "Massawa", country: "er", population: 23100 },
  { name: "Mendefera", country: "er", population: 17781 },
  { name: "Keren", country: "er", population: 74800 },
  { name: "Adi Keyh", country: "er", population: 13061 },
  { name: "Barentu", country: "er", population: 15891 },
  { name: "Assab", country: "er", population: 21300 },
  { name: "Villamalea", country: "es", population: 3955 },
  { name: "Villalonga", country: "es", population: 3996 },
  { name: "Ugíjar", country: "es", population: 2546 },
  { name: "Torre-Cardela", country: "es", population: 1107 },
  { name: "Tobarra", country: "es", population: 7884 },
  { name: "Tembleque", country: "es", population: 2202 },
  { name: "Tavernes Blanques", country: "es", population: 9033 },
  { name: "Retuerta de Bullaque", country: "es", population: 1049 },
  { name: "Posadas", country: "es", population: 7364 },
  { name: "Portocolom", country: "es", population: 4586 },
  { name: "Órgiva", country: "es", population: 5649 },
  { name: "Olvera", country: "es", population: 8515 },
  { name: "Tulu Bolo", country: "et", population: 10531 },
  { name: "Felege Neway", country: "et", population: 33429 },
  { name: "Dodola", country: "et", population: 23116 },
  { name: "Butajīra", country: "et", population: 30502 },
  { name: "Debre Tabor", country: "et", population: 32659 },
  { name: "Wenjī", country: "et", population: 17120 },
  { name: "Turmi", country: "et", population: 1087 },
  { name: "Kofelē", country: "et", population: 9643 },
  { name: "Debre Sīna", country: "et", population: 9409 },
  { name: "Bedelē", country: "et", population: 20293 },
  { name: "Ādīgrat", country: "et", population: 65000 },
  { name: "Metahāra", country: "et", population: 23403 },
  { name: "Ullava", country: "fi", population: 1048 },
  { name: "Tohmajärvi", country: "fi", population: 4911 },
  { name: "Nurmes", country: "fi", population: 8804 },
  { name: "Jurva", country: "fi", population: 4450 },
  { name: "Hyrynsalmi", country: "fi", population: 3087 },
  { name: "Aura", country: "fi", population: 3693 },
  { name: "Koukkuniemi", country: "fi", population: 1038 },
  { name: "Siikajoki", country: "fi", population: 1293 },
  { name: "Pyhäjärvi", country: "fi", population: 6138 },
  { name: "Pulkkila", country: "fi", population: 1661 },
  { name: "Lapinjärvi", country: "fi", population: 2937 },
  { name: "Kuusjoki", country: "fi", population: 1775 },
  { name: "Nadi", country: "fj", population: 42284 },
  { name: "Ba", country: "fj", population: 14596 },
  { name: "Suva", country: "fj", population: 77366 },
  { name: "Levuka", country: "fj", population: 8360 },
  { name: "Lautoka", country: "fj", population: 52500 },
  { name: "Labasa", country: "fj", population: 27949 },
  { name: "Stanley", country: "fk", population: 2213 },
  { name: "Kolonia", country: "fm", population: 6074 },
  { name: "Tofol", country: "fm", population: 9686 },
  { name: "Colonia", country: "fm", population: 7371 },
  { name: "Weno", country: "fm", population: 13856 },
  { name: "Kolonia Town", country: "fm", population: 5681 },
  { name: "Vágur", country: "fo", population: 1351 },
  { name: "Sørvágur", country: "fo", population: 1134 },
  { name: "Vestmanna", country: "fo", population: 1245 },
  { name: "Kollafjørður", country: "fo", population: 1049 },
  { name: "Klaksvík", country: "fo", population: 4664 },
  { name: "Fuglafjørður", country: "fo", population: 1542 },
  { name: "Tórshavn", country: "fo", population: 13200 },
  { name: "Tvøroyri", country: "fo", population: 1230 },
  { name: "Miðvágur", country: "fo", population: 1040 },
  { name: "Argir", country: "fo", population: 1871 },
  { name: "Hoyvík", country: "fo", population: 3940 },
  { name: "Tresses", country: "fr", population: 3907 },
  { name: "Trept", country: "fr", population: 1670 },
  { name: "Treize-Septiers", country: "fr", population: 2561 },
  { name: "Toulaud", country: "fr", population: 1579 },
  { name: "Thouars", country: "fr", population: 11517 },
  { name: "Thiers-sur-Thève", country: "fr", population: 1019 },
  { name: "Thérouanne", country: "fr", population: 1074 },
  { name: "Théoule-sur-Mer", country: "fr", population: 1347 },
  { name: "Théding", country: "fr", population: 2110 },
  { name: "Tavel", country: "fr", population: 1668 },
  { name: "Soultz-sous-Forêts", country: "fr", population: 2662 },
  { name: "Sormiou", country: "fr", population: 6809 },
  { name: "Omboué", country: "ga", population: 1667 },
  { name: "Mbigou", country: "ga", population: 4134 },
  { name: "Mayumba", country: "ga", population: 3996 },
  { name: "Lékoni", country: "ga", population: 3583 },
  { name: "Libreville", country: "ga", population: 578156 },
  { name: "Cocobeach", country: "ga", population: 1653 },
  { name: "Booué", country: "ga", population: 5787 },
  { name: "Bitam", country: "ga", population: 10297 },
  { name: "Oyem", country: "ga", population: 30870 },
  { name: "Mouila", country: "ga", population: 22469 },
  { name: "Moanda", country: "ga", population: 30151 },
  { name: "Mitzic", country: "ga", population: 3886 },
  { name: "Yapton", country: "gb", population: 5459 },
  { name: "Wroughton", country: "gb", population: 6474 },
  { name: "Woodcote", country: "gb", population: 2775 },
  { name: "Wittering", country: "gb", population: 2348 },
  { name: "Wisbech", country: "gb", population: 32489 },
  { name: "Wigan", country: "gb", population: 175405 },
  { name: "Weeting", country: "gb", population: 1839 },
  { name: "Waterbeach", country: "gb", population: 5795 },
  { name: "Waringstown", country: "gb", population: 2856 },
  { name: "Waltham Cross", country: "gb", population: 10000 },
  { name: "Wadebridge", country: "gb", population: 6599 },
  { name: "Upper Langwith", country: "gb", population: 2955 },
  { name: "Saint George's", country: "gd", population: 7500 },
  { name: "Victoria", country: "gd", population: 2256 },
  { name: "Saint David’s", country: "gd", population: 1321 },
  { name: "Gouyave", country: "gd", population: 3378 },
  { name: "Sauteurs", country: "gd", population: 1320 },
  { name: "Grenville", country: "gd", population: 2476 },
  { name: "Akhali Atoni", country: "ge", population: 1518 },
  { name: "Ozurgeti", country: "ge", population: 20636 },
  { name: "Khashuri", country: "ge", population: 27811 },
  { name: "Anaklia", country: "ge", population: 1300 },
  { name: "Telavi", country: "ge", population: 21800 },
  { name: "Oni", country: "ge", population: 3012 },
  { name: "Akhalk’alak’i", country: "ge", population: 9800 },
  { name: "Jvara", country: "ge", population: 3038 },
  { name: "Bich’vinta", country: "ge", population: 4198 },
  { name: "Bak’uriani", country: "ge", population: 1879 },
  { name: "Akhaldaba", country: "ge", population: 3640 },
  { name: "Ts’q’alt’ubo", country: "ge", population: 16736 },
  { name: "Mana", country: "gf", population: 5885 },
  { name: "Kourou", country: "gf", population: 24029 },
  { name: "Roura", country: "gf", population: 2229 },
  { name: "Sinnamary", country: "gf", population: 3180 },
  { name: "Iracoubo", country: "gf", population: 1536 },
  { name: "Camopi", country: "gf", population: 1295 },
  { name: "Rémire-Montjoly", country: "gf", population: 19029 },
  { name: "Matoury", country: "gf", population: 26350 },
  { name: "Apatou", country: "gf", population: 4752 },
  { name: "Macouria", country: "gf", population: 8773 },
  { name: "Saint-Georges", country: "gf", population: 2742 },
  { name: "Saint-Laurent-du-Maroni", country: "gf", population: 24287 },
  { name: "Saint Peter Port", country: "gg", population: 16488 },
  { name: "Yendi", country: "gh", population: 42972 },
  { name: "Tamale", country: "gh", population: 360579 },
  { name: "Atsiaman", country: "gh", population: 202932 },
  { name: "Takoradi", country: "gh", population: 232919 },
  { name: "Ho", country: "gh", population: 69998 },
  { name: "Aboso", country: "gh", population: 8790 },
  { name: "Zonno", country: "gh", population: 2000 },
  { name: "Wankyi", country: "gh", population: 31411 },
  { name: "Teshi Old Town", country: "gh", population: 144013 },
  { name: "Nungua", country: "gh", population: 70483 },
  { name: "Akropong", country: "gh", population: 11096 },
  { name: "Akim Swedru", country: "gh", population: 10000 },
  { name: "Gibraltar", country: "gi", population: 26544 },
  { name: "Qaqortoq", country: "gl", population: 3224 },
  { name: "Sisimiut", country: "gl", population: 5227 },
  { name: "Paamiut", country: "gl", population: 1862 },
  { name: "Ilulissat", country: "gl", population: 4413 },
  { name: "Narsaq", country: "gl", population: 1633 },
  { name: "Nuuk", country: "gl", population: 14798 },
  { name: "Maniitsoq", country: "gl", population: 2866 },
  { name: "Aasiaat", country: "gl", population: 3005 },
  { name: "Nanortalik", country: "gl", population: 1509 },
  { name: "Qasigiannguit", country: "gl", population: 1341 },
  { name: "Uummannaq", country: "gl", population: 1299 },
  { name: "Tasiilaq", country: "gl", population: 1829 },
  { name: "Sankwia", country: "gm", population: 1831 },
  { name: "Perai", country: "gm", population: 1026 },
  { name: "Koina", country: "gm", population: 3870 },
  { name: "Katchang", country: "gm", population: 1741 },
  { name: "Pateh Sam", country: "gm", population: 1164 },
  { name: "Kuntaur", country: "gm", population: 2534 },
  { name: "Jakhaly", country: "gm", population: 1793 },
  { name: "Denton", country: "gm", population: 1712 },
  { name: "Daba Kunda", country: "gm", population: 1302 },
  { name: "Toniataba", country: "gm", population: 1755 },
  { name: "Galleh Manda", country: "gm", population: 1872 },
  { name: "Banjul", country: "gm", population: 34589 },
  { name: "Conakry", country: "gn", population: 1767200 },
  { name: "Mali", country: "gn", population: 5479 },
  { name: "Coyah", country: "gn", population: 77103 },
  { name: "Kankan", country: "gn", population: 114009 },
  { name: "Kissidougou", country: "gn", population: 47099 },
  { name: "Kindia", country: "gn", population: 117062 },
  { name: "Lélouma", country: "gn", population: 4777 },
  { name: "Boké", country: "gn", population: 15460 },
  { name: "Mamou", country: "gn", population: 41619 },
  { name: "Fria", country: "gn", population: 44369 },
  { name: "Forécariah", country: "gn", population: 12358 },
  { name: "Yomou", country: "gn", population: 3614 },
  { name: "Sainte-Anne", country: "gp", population: 22859 },
  { name: "Anse-Bertrand", country: "gp", population: 5146 },
  { name: "Vieux-Habitants", country: "gp", population: 7728 },
  { name: "Port-Louis", country: "gp", population: 5515 },
  { name: "Petit-Canal", country: "gp", population: 8554 },
  { name: "Le Gosier", country: "gp", population: 28698 },
  { name: "Grand-Bourg", country: "gp", population: 5867 },
  { name: "Petit-Bourg", country: "gp", population: 24994 },
  { name: "Trois-Rivières", country: "gp", population: 8812 },
  { name: "Le Moule", country: "gp", population: 22692 },
  { name: "Bouillante", country: "gp", population: 7540 },
  { name: "Capesterre-Belle-Eau", country: "gp", population: 19821 },
  { name: "Mikomeseng", country: "gq", population: 5813 },
  { name: "San Antonio de Palé", country: "gq", population: 4433 },
  { name: "Bicurga", country: "gq", population: 2318 },
  { name: "Ayene", country: "gq", population: 3482 },
  { name: "Aconibe", country: "gq", population: 11192 },
  { name: "Machinda", country: "gq", population: 2897 },
  { name: "Ncue", country: "gq", population: 1683 },
  { name: "Río Campo", country: "gq", population: 1105 },
  { name: "Malabo", country: "gq", population: 155963 },
  { name: "Nsok", country: "gq", population: 3769 },
  { name: "Añisoc", country: "gq", population: 10191 },
  { name: "Santiago de Baney", country: "gq", population: 2365 },
  { name: "Chairóneia", country: "gr", population: 1382 },
  { name: "Káto Achaḯa", country: "gr", population: 5752 },
  { name: "Goúrnes", country: "gr", population: 1628 },
  { name: "Goúmero", country: "gr", population: 1072 },
  { name: "Áyios Konstandínos", country: "gr", population: 2471 },
  { name: "Atsipópoulo", country: "gr", population: 1392 },
  { name: "Atalánti", country: "gr", population: 4978 },
  { name: "Argalastí", country: "gr", population: 1285 },
  { name: "Anoixi", country: "gr", population: 6510 },
  { name: "Amfilochía", country: "gr", population: 4022 },
  { name: "Petrochóri", country: "gr", population: 1052 },
  { name: "Pentaplátano", country: "gr", population: 1015 },
  { name: "Villa Nueva", country: "gt", population: 406830 },
  { name: "Santa María Visitación", country: "gt", population: 1487 },
  { name: "Santa Catarina Barahona", country: "gt", population: 3214 },
  { name: "Santa Bárbara", country: "gt", population: 12906 },
  { name: "San Luis", country: "gt", population: 6776 },
  { name: "San Juan Tecuaco", country: "gt", population: 2210 },
  { name: "San Juan Bautista", country: "gt", population: 2680 },
  { name: "San Francisco la Unión", country: "gt", population: 1170 },
  { name: "San Cristóbal Verapaz", country: "gt", population: 19664 },
  { name: "Samayac", country: "gt", population: 8994 },
  { name: "Purulhá", country: "gt", population: 5370 },
  { name: "Patzité", country: "gt", population: 1003 },
  { name: "Piti Village", country: "gu", population: 1666 },
  { name: "Merizo Village", country: "gu", population: 2152 },
  { name: "Hagåtña", country: "gu", population: 1051 },
  { name: "Dededo Village", country: "gu", population: 44943 },
  { name: "Mongmong-Toto-Maite Village", country: "gu", population: 6825 },
  { name: "Tamuning", country: "gu", population: 19685 },
  { name: "Inarajan Village", country: "gu", population: 3052 },
  { name: "Yigo Village", country: "gu", population: 20539 },
  { name: "Chalan Pago-Ordot Village", country: "gu", population: 6822 },
  { name: "Santa Rita Village", country: "gu", population: 7500 },
  { name: "Hagåtña Village", country: "gu", population: 1051 },
  { name: "Yona Village", country: "gu", population: 6484 },
  { name: "Bissorã", country: "gw", population: 12688 },
  { name: "Canchungo", country: "gw", population: 6853 },
  { name: "Bissau", country: "gw", population: 388028 },
  { name: "Bolama", country: "gw", population: 10769 },
  { name: "Cacheu", country: "gw", population: 10490 },
  { name: "Bubaque", country: "gw", population: 9244 },
  { name: "Farim", country: "gw", population: 6792 },
  { name: "Mansôa", country: "gw", population: 7821 },
  { name: "Quinhámel", country: "gw", population: 3128 },
  { name: "Bafatá", country: "gw", population: 22521 },
  { name: "Gabú", country: "gw", population: 14430 },
  { name: "Catió", country: "gw", population: 9898 },
  { name: "Anna Regina", country: "gy", population: 12448 },
  { name: "Vreed-en-Hoop", country: "gy", population: 3073 },
  { name: "Mahaica Village", country: "gy", population: 4867 },
  { name: "Linden", country: "gy", population: 44690 },
  { name: "Skeldon", country: "gy", population: 5859 },
  { name: "Mahaicony Village", country: "gy", population: 2130 },
  { name: "New Amsterdam", country: "gy", population: 35039 },
  { name: "Mahdia", country: "gy", population: 4200 },
  { name: "Parika", country: "gy", population: 4081 },
  { name: "Georgetown", country: "gy", population: 235017 },
  { name: "Bartica", country: "gy", population: 11157 },
  { name: "Rosignol", country: "gy", population: 5782 },
  { name: "Zopilotepe", country: "hn", population: 1300 },
  { name: "Yorito", country: "hn", population: 2169 },
  { name: "Villa Nueva", country: "hn", population: 2295 },
  { name: "Tras Cerros", country: "hn", population: 4174 },
  { name: "Tegucigalpa", country: "hn", population: 850848 },
  { name: "San Nicolás", country: "hn", population: 2231 },
  { name: "San Luis", country: "hn", population: 5733 },
  { name: "San José del Potrero", country: "hn", population: 1091 },
  { name: "San Esteban", country: "hn", population: 3439 },
  { name: "Sandy Bay", country: "hn", population: 1072 },
  { name: "Río Esteban", country: "hn", population: 1208 },
  { name: "Río Bonito", country: "hn", population: 1054 },
  { name: "Vela Luka", country: "hr", population: 4380 },
  { name: "Stari Grad", country: "hr", population: 1885 },
  { name: "Široko Polje", country: "hr", population: 1012 },
  { name: "Mravince", country: "hr", population: 1628 },
  { name: "Koška", country: "hr", population: 1699 },
  { name: "Kastav", country: "hr", population: 2047 },
  { name: "Jelisavac", country: "hr", population: 1265 },
  { name: "Gornji Bogičevci", country: "hr", population: 1975 },
  { name: "Čeminac", country: "hr", population: 1113 },
  { name: "Bribir", country: "hr", population: 1762 },
  { name: "Bedekovčina", country: "hr", population: 3503 },
  { name: "Popovača", country: "hr", population: 4333 },
  { name: "Ti Port-de-Paix", country: "ht", population: 34657 },
  { name: "Grande Saline", country: "ht", population: 1787 },
  { name: "Gonaïves", country: "ht", population: 84961 },
  { name: "Thomassique", country: "ht", population: 4857 },
  { name: "Les Anglais", country: "ht", population: 8247 },
  { name: "Fort Liberté", country: "ht", population: 11465 },
  { name: "Fonds Verrettes", country: "ht", population: 2789 },
  { name: "Moron", country: "ht", population: 1830 },
  { name: "Marmelade", country: "ht", population: 2184 },
  { name: "Bombardopolis", country: "ht", population: 1974 },
  { name: "Anse à Galets", country: "ht", population: 7178 },
  { name: "Petit Trou de Nippes", country: "ht", population: 2130 },
  { name: "Túrkeve", country: "hu", population: 10016 },
  { name: "Tolcsva", country: "hu", population: 1860 },
  { name: "Tiszafüred", country: "hu", population: 12000 },
  { name: "Tállya", country: "hu", population: 2169 },
  { name: "Szajol", country: "hu", population: 3991 },
  { name: "Rákóczifalva", country: "hu", population: 5481 },
  { name: "Olaszliszka", country: "hu", population: 1799 },
  { name: "Napkor", country: "hu", population: 3786 },
  { name: "Nagyecsed", country: "hu", population: 6919 },
  { name: "Mikepércs", country: "hu", population: 3430 },
  { name: "Mezőzombor", country: "hu", population: 2549 },
  { name: "Fegyvernek", country: "hu", population: 6972 },
  { name: "Kisaran", country: "id", population: 129911 },
  { name: "Sungailiat", country: "id", population: 74719 },
  { name: "Sumedang", country: "id", population: 200000 },
  { name: "Ruteng", country: "id", population: 34569 },
  { name: "Purbalingga", country: "id", population: 56903 },
  { name: "Pamulang", country: "id", population: 174557 },
  { name: "Pagar Alam", country: "id", population: 70386 },
  { name: "Martapura", country: "id", population: 131449 },
  { name: "Katobu", country: "id", population: 31929 },
  { name: "Gambiran Satu", country: "id", population: 22525 },
  { name: "Bekasi", country: "id", population: 1520119 },
  { name: "Laikit, Laikit II (Dimembe)", country: "id", population: 8000 },
  { name: "Skibbereen", country: "ie", population: 2778 },
  { name: "Roscommon", country: "ie", population: 5693 },
  { name: "An Muileann gCearr", country: "ie", population: 17262 },
  { name: "Millstreet", country: "ie", population: 1555 },
  { name: "Laytown", country: "ie", population: 10889 },
  { name: "Cork", country: "ie", population: 190384 },
  { name: "Cabinteely", country: "ie", population: 10038 },
  { name: "Balrothery", country: "ie", population: 2017 },
  { name: "Ballysadare", country: "ie", population: 1350 },
  { name: "Bailieborough", country: "ie", population: 2683 },
  { name: "Askeaton", country: "ie", population: 1137 },
  { name: "Aghada", country: "ie", population: 1030 },
  { name: "Be’er Toviyya", country: "il", population: 1289 },
  { name: "Sde H̱emed", country: "il", population: 1019 },
  { name: "Ramot Menashe", country: "il", population: 1159 },
  { name: "Ora", country: "il", population: 1314 },
  { name: "Meiser", country: "il", population: 2017 },
  { name: "Kefar Warburg", country: "il", population: 1143 },
  { name: "‘Ilūṭ", country: "il", population: 8356 },
  { name: "Giv‘at Brenner", country: "il", population: 2678 },
  { name: "Port Erin", country: "im", population: 3530 },
  { name: "Douglas", country: "im", population: 26218 },
  { name: "Peel", country: "im", population: 3781 },
  { name: "Castletown", country: "im", population: 3100 },
  { name: "Laxey", country: "im", population: 1768 },
  { name: "Ramsey", country: "im", population: 7309 },
  { name: "Sāyalkudi", country: "in", population: 12415 },
  { name: "Sāvda", country: "in", population: 19903 },
  { name: "Sausar", country: "in", population: 26704 },
  { name: "Sakīt", country: "in", population: 7811 },
  { name: "Saharsa", country: "in", population: 145764 },
  { name: "Sabāthu", country: "in", population: 6691 },
  { name: "Rohru", country: "in", population: 8160 },
  { name: "Rāmānuj Ganj", country: "in", population: 10701 },
  { name: "Ramanathapuram", country: "in", population: 65314 },
  { name: "Rāhon", country: "in", population: 12485 },
  { name: "Rādhanpur", country: "in", population: 35457 },
  { name: "Rabūpura", country: "in", population: 13949 },
  { name: "Sīnah", country: "iq", population: 128776 },
  { name: "Dihok", country: "iq", population: 284000 },
  { name: "Nāḩiyat al Fuhūd", country: "iq", population: 21551 },
  { name: "Al Fāw", country: "iq", population: 104569 },
  { name: "‘Anat al Qadīmah", country: "iq", population: 19719 },
  { name: "Al Kūt", country: "iq", population: 315162 },
  { name: "Ţūz Khūrmātū", country: "iq", population: 59886 },
  { name: "Baqubah", country: "iq", population: 152550 },
  { name: "Kufa", country: "iq", population: 110000 },
  { name: "Al Hārithah", country: "iq", population: 92395 },
  { name: "Ad Dīwānīyah", country: "iq", population: 318801 },
  { name: "Sinjār", country: "iq", population: 38294 },
  { name: "Pādegān-e Manjīl", country: "ir", population: 17396 },
  { name: "Tonekābon", country: "ir", population: 37501 },
  { name: "Semnan", country: "ir", population: 124826 },
  { name: "Rāvar", country: "ir", population: 40167 },
  { name: "Mohr", country: "ir", population: 35000 },
  { name: "Kīsh", country: "ir", population: 20922 },
  { name: "pamas", country: "ir", population: 1081 },
  { name: "Shahrak-e Kūlūrī", country: "ir", population: 1941 },
  { name: "Ardabīl", country: "ir", population: 410753 },
  { name: "Ābdānān", country: "ir", population: 19360 },
  { name: "Eqbālīyeh", country: "ir", population: 36709 },
  { name: "Pasragad Branch", country: "ir", population: 787878 },
  { name: "Egilsstaðir", country: "is", population: 2303 },
  { name: "Laugar", country: "is", population: 1001 },
  { name: "Hafnarfjörður", country: "is", population: 26808 },
  { name: "Reyðarfjörður", country: "is", population: 1152 },
  { name: "Hveragerði", country: "is", population: 2288 },
  { name: "Vogar", country: "is", population: 1029 },
  { name: "Stykkishólmur", country: "is", population: 1108 },
  { name: "Sauðárkrókur", country: "is", population: 2575 },
  { name: "Vestmannaeyjar", country: "is", population: 4219 },
  { name: "Borgarnes", country: "is", population: 1875 },
  { name: "Siglufjörður", country: "is", population: 1190 },
  { name: "Ólafsvík", country: "is", population: 1010 },
  { name: "Buonalbergo", country: "it", population: 1332 },
  { name: "Buccino", country: "it", population: 2686 },
  { name: "Boscoreale", country: "it", population: 26861 },
  { name: "Borgo Santa Maria", country: "it", population: 3216 },
  { name: "Borgo Podgora", country: "it", population: 1623 },
  { name: "Boffalora Sopra Ticino", country: "it", population: 3992 },
  { name: "Darfo Boario Terme", country: "it", population: 14932 },
  { name: "Bibano", country: "it", population: 1561 },
  { name: "Berchidda", country: "it", population: 2754 },
  { name: "Beinasco", country: "it", population: 6469 },
  { name: "Bassignana", country: "it", population: 1366 },
  { name: "Bagnoli", country: "it", population: 23333 },
  { name: "Saint Helier", country: "je", population: 28000 },
  { name: "Buff Bay", country: "jm", population: 2757 },
  { name: "Montego Bay", country: "jm", population: 82867 },
  { name: "Lionel Town", country: "jm", population: 5362 },
  { name: "Linstead", country: "jm", population: 20660 },
  { name: "Sandy Bay", country: "jm", population: 4520 },
  { name: "Limit", country: "jm", population: 2321 },
  { name: "Saint Ann’s Bay", country: "jm", population: 13671 },
  { name: "Port Antonio", country: "jm", population: 14400 },
  { name: "Ulster Spring", country: "jm", population: 1411 },
  { name: "Spanish Town", country: "jm", population: 145018 },
  { name: "Discovery Bay", country: "jm", population: 2535 },
  { name: "Bath", country: "jm", population: 2382 },
  { name: "Zarqa", country: "jo", population: 792665 },
  { name: "Al Khinzīrah", country: "jo", population: 5231 },
  { name: "Adir", country: "jo", population: 4441 },
  { name: "Yarqā", country: "jo", population: 4786 },
  { name: "Umm as Summāq", country: "jo", population: 18274 },
  { name: "Şabḩā", country: "jo", population: 5315 },
  { name: "Ma'an", country: "jo", population: 50350 },
  { name: "Ḩakamā", country: "jo", population: 7075 },
  { name: "Burmā", country: "jo", population: 4735 },
  { name: "Bayt Yāfā", country: "jo", population: 7788 },
  { name: "Bayt Īdis", country: "jo", population: 4723 },
  { name: "Rukban", country: "jo", population: 85000 },
  { name: "Yukuhashi", country: "jp", population: 71277 },
  { name: "Yokkaichi", country: "jp", population: 295841 },
  { name: "Yamazakichō-nakabirose", country: "jp", population: 25115 },
  { name: "Tokuyama", country: "jp", population: 101133 },
  { name: "Tenri", country: "jp", population: 71052 },
  { name: "Sueyoshichō-ninokata", country: "jp", population: 19986 },
  { name: "Satte", country: "jp", population: 55286 },
  { name: "Sakata", country: "jp", population: 100446 },
  { name: "Moriguchi", country: "jp", population: 148350 },
  { name: "Miyazu", country: "jp", population: 21632 },
  { name: "Mishima", country: "jp", population: 113479 },
  { name: "Masuda", country: "jp", population: 48576 },
  { name: "Ruiru", country: "ke", population: 490120 },
  { name: "Rongai", country: "ke", population: 30471 },
  { name: "Nakuru", country: "ke", population: 259903 },
  { name: "Makueni Boma", country: "ke", population: 20681 },
  { name: "Kwale", country: "ke", population: 4883 },
  { name: "Kilifi", country: "ke", population: 46118 },
  { name: "Kapenguria", country: "ke", population: 56000 },
  { name: "Kangundo", country: "ke", population: 10094 },
  { name: "Bungoma", country: "ke", population: 55962 },
  { name: "Sotik", country: "ke", population: 2600 },
  { name: "Shimoni", country: "ke", population: 2374 },
  { name: "Ngong", country: "ke", population: 9519 },
  { name: "Kerben", country: "kg", population: 14141 },
  { name: "Kyzyl-Eshme", country: "kg", population: 1100 },
  { name: "Isfana", country: "kg", population: 16952 },
  { name: "Tokmok", country: "kg", population: 63047 },
  { name: "Cholpon-Ata", country: "kg", population: 18595 },
  { name: "Bazar-Korgon", country: "kg", population: 27704 },
  { name: "Toktogul", country: "kg", population: 19336 },
  { name: "Suluktu", country: "kg", population: 15019 },
  { name: "Daroot-Korgon", country: "kg", population: 4700 },
  { name: "Tyup", country: "kg", population: 13437 },
  { name: "Tash-Kumyr", country: "kg", population: 23594 },
  { name: "Kaindy", country: "kg", population: 10616 },
  { name: "Smach Mean Chey", country: "kh", population: 29329 },
  { name: "Siem Reap", country: "kh", population: 139458 },
  { name: "Kampong Speu", country: "kh", population: 33231 },
  { name: "Phnom Penh", country: "kh", population: 1573544 },
  { name: "Svay Rieng", country: "kh", population: 23956 },
  { name: "Battambang", country: "kh", population: 150444 },
  { name: "Sisophon", country: "kh", population: 23218 },
  { name: "Pursat", country: "kh", population: 52476 },
  { name: "Samraong", country: "kh", population: 18694 },
  { name: "Pailin", country: "kh", population: 17850 },
  { name: "Prey Veng", country: "kh", population: 33079 },
  { name: "Kratié", country: "kh", population: 19975 },
  { name: "Rawannawi Village", country: "ki", population: 1075 },
  { name: "Makin Village", country: "ki", population: 1834 },
  { name: "Bairiki Village", country: "ki", population: 2766 },
  { name: "Temaiku Village", country: "ki", population: 2011 },
  { name: "Bikenibeu Village", country: "ki", population: 6170 },
  { name: "Eita Village", country: "ki", population: 2299 },
  { name: "Betio Village", country: "ki", population: 12509 },
  { name: "Tarawa", country: "ki", population: 40311 },
  { name: "Teaoraereke Village", country: "ki", population: 3939 },
  { name: "Ambo Village", country: "ki", population: 1688 },
  { name: "Bonriki Village", country: "ki", population: 2119 },
  { name: "Banraeaba Village", country: "ki", population: 1789 },
  { name: "Moutsamoudou", country: "km", population: 23594 },
  { name: "Itsandzéni", country: "km", population: 1382 },
  { name: "Moroni", country: "km", population: 42872 },
  { name: "Koni-Djodjo", country: "km", population: 8109 },
  { name: "Daji", country: "km", population: 2676 },
  { name: "Mjimandra", country: "km", population: 3114 },
  { name: "Chitrouni", country: "km", population: 1081 },
  { name: "Mirontsi", country: "km", population: 10168 },
  { name: "Lingoni", country: "km", population: 3583 },
  { name: "Assimpao", country: "km", population: 1112 },
  { name: "Mvouni", country: "km", population: 4791 },
  { name: "Sima", country: "km", population: 10374 },
  { name: "Trinity", country: "kn", population: 1692 },
  { name: "Basseterre", country: "kn", population: 12920 },
  { name: "Saint Paul’s", country: "kn", population: 2460 },
  { name: "Nicola Town", country: "kn", population: 2059 },
  { name: "Fig Tree", country: "kn", population: 2922 },
  { name: "Market Shop", country: "kn", population: 2568 },
  { name: "Middle Island", country: "kn", population: 2332 },
  { name: "Charlestown", country: "kn", population: 1538 },
  { name: "Yŏnggwang-ŭp", country: "kp", population: 21882 },
  { name: "Musan-ŭp", country: "kp", population: 21739 },
  { name: "Kapsan-ŭp", country: "kp", population: 20406 },
  { name: "Wŏnsan", country: "kp", population: 329207 },
  { name: "Onsŏng", country: "kp", population: 19806 },
  { name: "Iwŏn-ŭp", country: "kp", population: 26364 },
  { name: "Sŭngjibaegam", country: "kp", population: 11786 },
  { name: "Samho-rodongjagu", country: "kp", population: 19025 },
  { name: "Kangdong-ŭp", country: "kp", population: 28922 },
  { name: "Kyŏngwŏn", country: "kp", population: 12650 },
  { name: "Kyŏngsŏng", country: "kp", population: 35604 },
  { name: "Sinmak", country: "kp", population: 18669 },
  { name: "Wŏnju", country: "kr", population: 332849 },
  { name: "Beolgyo", country: "kr", population: 19842 },
  { name: "Gwangju", country: "kr", population: 81780 },
  { name: "Sinan", country: "kr", population: 53150 },
  { name: "Yeongdong", country: "kr", population: 24154 },
  { name: "Seonghwan", country: "kr", population: 29239 },
  { name: "Jeongeup", country: "kr", population: 139876 },
  { name: "Santyoku", country: "kr", population: 42145 },
  { name: "Nangen", country: "kr", population: 81257 },
  { name: "Jeju City", country: "kr", population: 486306 },
  { name: "T’aebaek", country: "kr", population: 48962 },
  { name: "Puan", country: "kr", population: 22267 },
  { name: "Ash Shāmīyah", country: "kw", population: 13762 },
  { name: "Al Aḩmadī", country: "kw", population: 637411 },
  { name: "Az Zawr", country: "kw", population: 5750 },
  { name: "As Sālimīyah", country: "kw", population: 147649 },
  { name: "Şabāḩ as Sālim", country: "kw", population: 139163 },
  { name: "Abu Fatira", country: "kw", population: 1240 },
  { name: "Ḩawallī", country: "kw", population: 164212 },
  { name: "Al Wafrah", country: "kw", population: 10017 },
  { name: "Al Faḩāḩīl", country: "kw", population: 68290 },
  { name: "Abu Al Hasaniya", country: "kw", population: 1350 },
  { name: "Al Funayţīs", country: "kw", population: 1878 },
  { name: "Al Manqaf", country: "kw", population: 39025 },
  { name: "George Town", country: "ky", population: 29370 },
  { name: "East End", country: "ky", population: 1639 },
  { name: "Bodden Town", country: "ky", population: 10341 },
  { name: "North Side", country: "ky", population: 1184 },
  { name: "West Bay", country: "ky", population: 11269 },
  { name: "Shalqar", country: "kz", population: 27399 },
  { name: "Say-Utes", country: "kz", population: 1571 },
  { name: "Taraz", country: "kz", population: 358153 },
  { name: "Nur-Sultan", country: "kz", population: 345604 },
  { name: "Chu", country: "kz", population: 39785 },
  { name: "Matay", country: "kz", population: 4139 },
  { name: "Kurchum", country: "kz", population: 8800 },
  { name: "Esil", country: "kz", population: 13504 },
  { name: "Derzhavinsk", country: "kz", population: 13856 },
  { name: "Auezov", country: "kz", population: 4209 },
  { name: "Aktogay", country: "kz", population: 3741 },
  { name: "Abay", country: "kz", population: 35454 },
  { name: "Salavan", country: "la", population: 5521 },
  { name: "Pakxan", country: "la", population: 21967 },
  { name: "Pakxong", country: "la", population: 5000 },
  { name: "Luang Namtha", country: "la", population: 3225 },
  { name: "Muang Xay", country: "la", population: 25000 },
  { name: "Muang Không", country: "la", population: 15000 },
  { name: "Champasak", country: "la", population: 12994 },
  { name: "Ban Thatèng", country: "la", population: 1500 },
  { name: "Vangviang", country: "la", population: 25000 },
  { name: "Lamam", country: "la", population: 4463 },
  { name: "Attapeu", country: "la", population: 4297 },
  { name: "Xam Nua", country: "la", population: 46800 },
  { name: "Ghazieh", country: "lb", population: 50000 },
  { name: "Batroûn", country: "lb", population: 10852 },
  { name: "Beirut", country: "lb", population: 1916100 },
  { name: "Nabatîyé et Tahta", country: "lb", population: 120000 },
  { name: "Sidon", country: "lb", population: 163554 },
  { name: "Ain Ebel", country: "lb", population: 2000 },
  { name: "Tyre", country: "lb", population: 135204 },
  { name: "Hrajel", country: "lb", population: 8000 },
  { name: "Baalbek", country: "lb", population: 30916 },
  { name: "Tripoli", country: "lb", population: 229398 },
  { name: "Aanjar", country: "lb", population: 2400 },
  { name: "Bhamdoun", country: "lb", population: 1500 },
  { name: "Micoud", country: "lc", population: 3406 },
  { name: "Soufrière", country: "lc", population: 2918 },
  { name: "Vieux Fort", country: "lc", population: 4574 },
  { name: "Bisee", country: "lc", population: 12980 },
  { name: "Anse La Raye", country: "lc", population: 1256 },
  { name: "Gros Islet", country: "lc", population: 2362 },
  { name: "Dennery", country: "lc", population: 2870 },
  { name: "Canaries", country: "lc", population: 1862 },
  { name: "Praslin", country: "lc", population: 1906 },
  { name: "Laborie", country: "lc", population: 1131 },
  { name: "Castries", country: "lc", population: 20000 },
  { name: "Vaduz", country: "li", population: 5197 },
  { name: "Eschen", country: "li", population: 4008 },
  { name: "Gamprin", country: "li", population: 1268 },
  { name: "Ruggell", country: "li", population: 1862 },
  { name: "Schellenberg", country: "li", population: 1004 },
  { name: "Triesenberg", country: "li", population: 2689 },
  { name: "Triesen", country: "li", population: 4701 },
  { name: "Balzers", country: "li", population: 4447 },
  { name: "Mauren", country: "li", population: 3626 },
  { name: "Schaan", country: "li", population: 5748 },
  { name: "Kotikawatta", country: "lk", population: 64565 },
  { name: "Homagama", country: "lk", population: 34664 },
  { name: "Gampola", country: "lk", population: 24283 },
  { name: "Galle", country: "lk", population: 93118 },
  { name: "Talawakele", country: "lk", population: 3545 },
  { name: "Moratuwa", country: "lk", population: 168280 },
  { name: "Jaffna", country: "lk", population: 169102 },
  { name: "Eravur Town", country: "lk", population: 22982 },
  { name: "Valvedditturai", country: "lk", population: 78205 },
  { name: "Polonnaruwa", country: "lk", population: 13900 },
  { name: "Ella Town", country: "lk", population: 5000 },
  { name: "Horawala Junction", country: "lk", population: 7500 },
  { name: "Robertsport", country: "lr", population: 11969 },
  { name: "Voinjama", country: "lr", population: 26594 },
  { name: "Tubmanburg", country: "lr", population: 3155 },
  { name: "Fish Town", country: "lr", population: 3328 },
  { name: "Bensonville", country: "lr", population: 33188 },
  { name: "Bopolu", country: "lr", population: 2908 },
  { name: "New Yekepa", country: "lr", population: 24695 },
  { name: "Cestos City", country: "lr", population: 2578 },
  { name: "Gbarnga", country: "lr", population: 45835 },
  { name: "Kakata", country: "lr", population: 33945 },
  { name: "Harper", country: "lr", population: 32661 },
  { name: "Greenville", country: "lr", population: 16434 },
  { name: "Teyateyaneng", country: "ls", population: 5115 },
  { name: "Maseru", country: "ls", population: 118355 },
  { name: "Quthing", country: "ls", population: 24130 },
  { name: "Thaba-Tseka", country: "ls", population: 5423 },
  { name: "Nako", country: "ls", population: 13146 },
  { name: "Mohale’s Hoek", country: "ls", population: 28310 },
  { name: "Leribe", country: "ls", population: 47675 },
  { name: "Mafeteng", country: "ls", population: 57059 },
  { name: "Butha-Buthe", country: "ls", population: 16330 },
  { name: "Qacha’s Nek", country: "ls", population: 25573 },
  { name: "Mokhotlong", country: "ls", population: 8809 },
  { name: "Maputsoe", country: "ls", population: 32117 },
  { name: "Utena", country: "lt", population: 33240 },
  { name: "Naujoji Akmene", country: "lt", population: 11922 },
  { name: "Daugai", country: "lt", population: 1279 },
  { name: "Pilaitė", country: "lt", population: 20320 },
  { name: "Šilalė", country: "lt", population: 6026 },
  { name: "Akmenė", country: "lt", population: 3026 },
  { name: "Ramygala", country: "lt", population: 1440 },
  { name: "Pervalka", country: "lt", population: 3097 },
  { name: "Kybartai", country: "lt", population: 5631 },
  { name: "Dainava (Kaunas)", country: "lt", population: 70000 },
  { name: "Šventoji", country: "lt", population: 2000 },
  { name: "Lentvaris", country: "lt", population: 10004 },
  { name: "Troisvierges", country: "lu", population: 1457 },
  { name: "Wiltz", country: "lu", population: 4816 },
  { name: "Junglinster", country: "lu", population: 2358 },
  { name: "Helmsange", country: "lu", population: 2193 },
  { name: "Bissen", country: "lu", population: 2547 },
  { name: "Mertert", country: "lu", population: 1163 },
  { name: "Kayl", country: "lu", population: 4396 },
  { name: "Esch-sur-Alzette", country: "lu", population: 28228 },
  { name: "Rollingen", country: "lu", population: 1448 },
  { name: "Clemency", country: "lu", population: 1821 },
  { name: "Bettendorf", country: "lu", population: 1072 },
  { name: "Viļaka", country: "lv", population: 1525 },
  { name: "Varakļāni", country: "lv", population: 2106 },
  { name: "Talsi", country: "lv", population: 12022 },
  { name: "Riga", country: "lv", population: 742572 },
  { name: "Grobiņa", country: "lv", population: 4203 },
  { name: "Vecrīga", country: "lv", population: 3336 },
  { name: "Roja", country: "lv", population: 1800 },
  { name: "Piņķi", country: "lv", population: 3033 },
  { name: "Ogre", country: "lv", population: 26760 },
  { name: "Limbaži", country: "lv", population: 8974 },
  { name: "Jaunciems", country: "lv", population: 2684 },
  { name: "Cēsis", country: "lv", population: 18198 },
  { name: "Şabrātah", country: "ly", population: 102038 },
  { name: "Marādah", country: "ly", population: 2229 },
  { name: "Hūn", country: "ly", population: 18878 },
  { name: "Jālū", country: "ly", population: 3334 },
  { name: "Al Jumayl", country: "ly", population: 102000 },
  { name: "Idrī", country: "ly", population: 4611 },
  { name: "Tobruk", country: "ly", population: 121052 },
  { name: "Al Ajaylat", country: "ly", population: 130546 },
  { name: "Al Jadīd", country: "ly", population: 126386 },
  { name: "Al Abyār", country: "ly", population: 32563 },
  { name: "Bani Walid", country: "ly", population: 46350 },
  { name: "Az Zāwīyah", country: "ly", population: 200000 },
  { name: "Tiflet", country: "ma", population: 70010 },
  { name: "Rabat", country: "ma", population: 1655753 },
  { name: "Sidi Ifni", country: "ma", population: 20000 },
  { name: "Saidia", country: "ma", population: 3871 },
  { name: "Reçani", country: "ma", population: 20469 },
  { name: "Larache", country: "ma", population: 109294 },
  { name: "Kenitra", country: "ma", population: 366570 },
  { name: "Taghazout", country: "ma", population: 5233 },
  { name: "Zawyat ech Cheïkh", country: "ma", population: 2000 },
  { name: "Tinghir", country: "ma", population: 36795 },
  { name: "Taroudant", country: "ma", population: 71133 },
  { name: "Tafraout", country: "ma", population: 4924 },
  { name: "Monte-Carlo", country: "mc", population: 16012 },
  { name: "Saint-Roman", country: "mc", population: 3000 },
  { name: "Moneghetti", country: "mc", population: 3000 },
  { name: "Fontvieille", country: "mc", population: 3602 },
  { name: "La Condamine", country: "mc", population: 12167 },
  { name: "Iargara", country: "md", population: 5600 },
  { name: "Teleneşti", country: "md", population: 6633 },
  { name: "Şoldăneşti", country: "md", population: 6160 },
  { name: "Rezina", country: "md", population: 9806 },
  { name: "Dolna", country: "md", population: 1155 },
  { name: "Cantemir", country: "md", population: 3829 },
  { name: "Bălţi", country: "md", population: 125000 },
  { name: "Nisporeni", country: "md", population: 11718 },
  { name: "Basarabeasca", country: "md", population: 10809 },
  { name: "Biruinţa", country: "md", population: 4700 },
  { name: "Sîngerei", country: "md", population: 14600 },
  { name: "Drochia", country: "md", population: 16080 },
  { name: "Igalo", country: "me", population: 3754 },
  { name: "Bijela", country: "me", population: 2395 },
  { name: "Plužine", country: "me", population: 1494 },
  { name: "Mataguži", country: "me", population: 1132 },
  { name: "Nikšić", country: "me", population: 58212 },
  { name: "Mojanovići", country: "me", population: 1800 },
  { name: "Kolašin", country: "me", population: 2989 },
  { name: "Berane", country: "me", population: 11073 },
  { name: "Danilovgrad", country: "me", population: 5208 },
  { name: "Rožaje", country: "me", population: 9121 },
  { name: "Kotor", country: "me", population: 5345 },
  { name: "Herceg Novi", country: "me", population: 19536 },
  { name: "Marigot", country: "mf", population: 5700 },
  { name: "Ikalamavony", country: "mg", population: 15601 },
  { name: "Fandrandava", country: "mg", population: 9000 },
  { name: "Antsohimbondrona", country: "mg", population: 32080 },
  { name: "Vondrozo", country: "mg", population: 19949 },
  { name: "Kopoky", country: "mg", population: 15000 },
  { name: "Ifanadiana", country: "mg", population: 17543 },
  { name: "Ankazondandy", country: "mg", population: 16000 },
  { name: "Soavinandriana", country: "mg", population: 40453 },
  { name: "Marolambo", country: "mg", population: 26160 },
  { name: "Mahanoro", country: "mg", population: 39879 },
  { name: "Belo sur Tsiribihina", country: "mg", population: 23900 },
  { name: "Bealanana", country: "mg", population: 16948 },
  { name: "Arno", country: "mh", population: 2068 },
  { name: "Majuro", country: "mh", population: 25400 },
  { name: "Jabor", country: "mh", population: 1200 },
  { name: "Ebaye", country: "mh", population: 15000 },
  { name: "Vrutok", country: "mk", population: 5999 },
  { name: "Strelci", country: "mk", population: 1563 },
  { name: "Dolno Srpci", country: "mk", population: 1433 },
  { name: "Radolista", country: "mk", population: 2814 },
  { name: "Otlja", country: "mk", population: 2326 },
  { name: "Mogila", country: "mk", population: 4392 },
  { name: "Makedonska Kamenica", country: "mk", population: 8114 },
  { name: "Gostivar", country: "mk", population: 50974 },
  { name: "Čaška", country: "mk", population: 2878 },
  { name: "Sredno Konjari", country: "mk", population: 1089 },
  { name: "Šipkovica", country: "mk", population: 8228 },
  { name: "Oktisi", country: "mk", population: 2692 },
  { name: "Ntossoni", country: "ml", population: 8700 },
  { name: "Koutiala", country: "ml", population: 99353 },
  { name: "Ségou", country: "ml", population: 153349 },
  { name: "Kita", country: "ml", population: 5769 },
  { name: "Ténenkou", country: "ml", population: 7471 },
  { name: "Araouane", country: "ml", population: 4026 },
  { name: "Kinmparana", country: "ml", population: 6014 },
  { name: "Kolondiéba", country: "ml", population: 10041 },
  { name: "Bougouni", country: "ml", population: 35450 },
  { name: "Kayes", country: "ml", population: 78406 },
  { name: "Djénné", country: "ml", population: 22382 },
  { name: "Sikasso", country: "ml", population: 144786 },
  { name: "Magway", country: "mm", population: 96954 },
  { name: "Myitkyina", country: "mm", population: 90894 },
  { name: "Kanbe", country: "mm", population: 58146 },
  { name: "Twante", country: "mm", population: 46516 },
  { name: "Myanaung", country: "mm", population: 42252 },
  { name: "Myeik", country: "mm", population: 173298 },
  { name: "Mawlamyinegyunn", country: "mm", population: 39115 },
  { name: "Kyaukse", country: "mm", population: 50480 },
  { name: "Nyaungshwe", country: "mm", population: 1300 },
  { name: "Pulei", country: "mm", population: 2001 },
  { name: "Thaton", country: "mm", population: 123727 },
  { name: "Taungdwingyi", country: "mm", population: 70094 },
  { name: "Undurkhaan", country: "mn", population: 14723 },
  { name: "Choyr", country: "mn", population: 9895 },
  { name: "Mörön", country: "mn", population: 27690 },
  { name: "Saynshand", country: "mn", population: 19891 },
  { name: "Ulan Bator", country: "mn", population: 844818 },
  { name: "Hovd", country: "mn", population: 27924 },
  { name: "Arvayheer", country: "mn", population: 25622 },
  { name: "Зуунмод", country: "mn", population: 17630 },
  { name: "Ölgii", country: "mn", population: 28400 },
  { name: "Dzuunmod", country: "mn", population: 14660 },
  { name: "Mandalgovi", country: "mn", population: 15430 },
  { name: "Sühbaatar", country: "mn", population: 24235 },
  { name: "San Jose Village", country: "mp", population: 15000 },
  { name: "Saipan", country: "mp", population: 48220 },
  { name: "Saint-Esprit", country: "mq", population: 8508 },
  { name: "Sainte-Marie", country: "mq", population: 20380 },
  { name: "Le Vauclin", country: "mq", population: 7812 },
  { name: "Le Lorrain", country: "mq", population: 8341 },
  { name: "Rivière-Pilote", country: "mq", population: 13359 },
  { name: "Ducos", country: "mq", population: 17394 },
  { name: "Le Gros-Morne", country: "mq", population: 11025 },
  { name: "Sainte-Luce", country: "mq", population: 9196 },
  { name: "Les Trois-Îlets", country: "mq", population: 5657 },
  { name: "Le Robert", country: "mq", population: 23814 },
  { name: "Basse-Pointe", country: "mq", population: 4037 },
  { name: "Le Morne-Rouge", country: "mq", population: 5469 },
  { name: "Nouakchott", country: "mr", population: 661400 },
  { name: "Kaédi", country: "mr", population: 55374 },
  { name: "Aleg", country: "mr", population: 15211 },
  { name: "Akjoujt", country: "mr", population: 11500 },
  { name: "Aioun", country: "mr", population: 12635 },
  { name: "Atar", country: "mr", population: 24021 },
  { name: "Tidjikja", country: "mr", population: 6000 },
  { name: "Kiffa", country: "mr", population: 40281 },
  { name: "Nouadhibou", country: "mr", population: 72337 },
  { name: "Chingueṭṭi", country: "mr", population: 4711 },
  { name: "Barkéwol", country: "mr", population: 1100 },
  { name: "Zouerate", country: "mr", population: 38000 },
  { name: "San Pawl il-Baħar", country: "mt", population: 14057 },
  { name: "Rabat", country: "mt", population: 11470 },
  { name: "Mqabba", country: "mt", population: 2891 },
  { name: "Balzan", country: "mt", population: 3240 },
  { name: "Gudja", country: "mt", population: 3023 },
  { name: "San Ġwann", country: "mt", population: 12346 },
  { name: "Paola", country: "mt", population: 9257 },
  { name: "Imsida", country: "mt", population: 6920 },
  { name: "Mosta", country: "mt", population: 17789 },
  { name: "Imġarr", country: "mt", population: 3000 },
  { name: "Floriana", country: "mt", population: 2516 },
  { name: "Haz-Zebbug", country: "mt", population: 11063 },
  { name: "Petite Case Noyale", country: "mu", population: 1525 },
  { name: "Tamarin", country: "mu", population: 3764 },
  { name: "Bois des Amourettes", country: "mu", population: 1986 },
  { name: "Congomah", country: "mu", population: 1956 },
  { name: "Roches Noire", country: "mu", population: 5733 },
  { name: "Calebasses", country: "mu", population: 4648 },
  { name: "The Vale", country: "mu", population: 3707 },
  { name: "Moka", country: "mu", population: 8842 },
  { name: "Grand Baie", country: "mu", population: 11512 },
  { name: "Crève Coeur", country: "mu", population: 3097 },
  { name: "Beau Vallon", country: "mu", population: 7016 },
  { name: "Albion", country: "mu", population: 3254 },
  { name: "Kudahuvadhoo", country: "mv", population: 1562 },
  { name: "Hithadhoo", country: "mv", population: 9927 },
  { name: "Vilufushi", country: "mv", population: 2077 },
  { name: "Open Stage", country: "mv", population: 1258 },
  { name: "Eydhafushi", country: "mv", population: 2808 },
  { name: "Male", country: "mv", population: 103693 },
  { name: "Manadhoo", country: "mv", population: 1580 },
  { name: "Veymandoo", country: "mv", population: 1100 },
  { name: "Fonadhoo", country: "mv", population: 1773 },
  { name: "Ugoofaaru", country: "mv", population: 1575 },
  { name: "Hulhumale", country: "mv", population: 5000 },
  { name: "Guraidhoo", country: "mv", population: 1800 },
  { name: "Blantyre", country: "mw", population: 584877 },
  { name: "Chitipa", country: "mw", population: 8824 },
  { name: "Luchenza", country: "mw", population: 11939 },
  { name: "Liwonde", country: "mw", population: 22469 },
  { name: "Lilongwe", country: "mw", population: 646750 },
  { name: "Machinga", country: "mw", population: 1418 },
  { name: "Ntcheu", country: "mw", population: 10445 },
  { name: "Nkhotakota", country: "mw", population: 24865 },
  { name: "Mchinji", country: "mw", population: 18305 },
  { name: "Ntchisi", country: "mw", population: 7918 },
  { name: "Mangochi", country: "mw", population: 40236 },
  { name: "Chikwawa", country: "mw", population: 6987 },
  { name: "Cuendo", country: "mx", population: 1473 },
  { name: "San Antonio Enchisi", country: "mx", population: 4870 },
  { name: "La Esperanza", country: "mx", population: 1458 },
  { name: "San Antonio Nixini", country: "mx", population: 1182 },
  { name: "Tlacuitlapa", country: "mx", population: 2234 },
  { name: "Ignacio Zaragoza", country: "mx", population: 1386 },
  { name: "Colonia Veinte de Noviembre", country: "mx", population: 1276 },
  { name: "Cristo Rey", country: "mx", population: 1934 },
  { name: "El Limón de los Ramos", country: "mx", population: 3191 },
  { name: "Francisco I. Madero", country: "mx", population: 4550 },
  { name: "Buenavista", country: "mx", population: 2536 },
  { name: "Bacobampo", country: "mx", population: 8539 },
  { name: "Ayer Hangat", country: "my", population: 10000 },
  { name: "Taman Senai", country: "my", population: 73176 },
  { name: "Johor Bahru", country: "my", population: 802489 },
  { name: "Ranau", country: "my", population: 19294 },
  { name: "Butterworth", country: "my", population: 107591 },
  { name: "Kepala Batas", country: "my", population: 17131 },
  { name: "Simanggang", country: "my", population: 27207 },
  { name: "Kampung Sungai Ara", country: "my", population: 140849 },
  { name: "Petaling Jaya", country: "my", population: 520698 },
  { name: "Batu Gajah", country: "my", population: 46183 },
  { name: "Mukah", country: "my", population: 49900 },
  { name: "Tanah Merah", country: "my", population: 29116 },
  { name: "Tete", country: "mz", population: 129316 },
  { name: "Chokwé", country: "mz", population: 63695 },
  { name: "Nacala", country: "mz", population: 224795 },
  { name: "Lichinga", country: "mz", population: 109839 },
  { name: "Chibuto", country: "mz", population: 59165 },
  { name: "Inhambane", country: "mz", population: 73884 },
  { name: "Xai-Xai", country: "mz", population: 127366 },
  { name: "Mozambique", country: "mz", population: 54315 },
  { name: "Maputo", country: "mz", population: 1191613 },
  { name: "Pemba", country: "mz", population: 108737 },
  { name: "Matola", country: "mz", population: 675422 },
  { name: "Mandimba", country: "mz", population: 118922 },
  { name: "Khorixas", country: "na", population: 12021 },
  { name: "Bagani", country: "na", population: 2000 },
  { name: "Outjo", country: "na", population: 6557 },
  { name: "Tses", country: "na", population: 2000 },
  { name: "Oranjemund", country: "na", population: 8496 },
  { name: "Oshakati", country: "na", population: 33618 },
  { name: "Ongwediva", country: "na", population: 9654 },
  { name: "Keetmanshoop", country: "na", population: 15608 },
  { name: "Omuthiya", country: "na", population: 5000 },
  { name: "Windhoek", country: "na", population: 268132 },
  { name: "Usakos", country: "na", population: 9147 },
  { name: "Otjimbingwe", country: "na", population: 7677 },
  { name: "Dumbéa", country: "nc", population: 19346 },
  { name: "Canala", country: "nc", population: 3538 },
  { name: "Bourail", country: "nc", population: 4826 },
  { name: "Nouméa", country: "nc", population: 93060 },
  { name: "Houaïlou", country: "nc", population: 4562 },
  { name: "Voh", country: "nc", population: 2272 },
  { name: "Ouégoa", country: "nc", population: 2127 },
  { name: "Wé", country: "nc", population: 10375 },
  { name: "Thio", country: "nc", population: 2748 },
  { name: "Pouembout", country: "nc", population: 1511 },
  { name: "Yaté-Barrage", country: "nc", population: 1871 },
  { name: "Mont-Dore", country: "nc", population: 24680 },
  { name: "Birni N Konni", country: "ne", population: 48103 },
  { name: "Bilma", country: "ne", population: 2291 },
  { name: "Madaoua", country: "ne", population: 24804 },
  { name: "Dakoro", country: "ne", population: 19798 },
  { name: "Tanout", country: "ne", population: 15204 },
  { name: "Say", country: "ne", population: 10387 },
  { name: "Maïné Soroa", country: "ne", population: 10699 },
  { name: "Diffa", country: "ne", population: 27948 },
  { name: "Agadez", country: "ne", population: 124324 },
  { name: "Mayahi", country: "ne", population: 22183 },
  { name: "Tibiri", country: "ne", population: 20019 },
  { name: "Niamey", country: "ne", population: 774235 },
  { name: "Ukata", country: "ng", population: 6188 },
  { name: "Talata Mafara", country: "ng", population: 39045 },
  { name: "Saminaka", country: "ng", population: 7165 },
  { name: "Owerri", country: "ng", population: 215038 },
  { name: "Obonoma", country: "ng", population: 68584 },
  { name: "Kafin Hausa", country: "ng", population: 12609 },
  { name: "Iwo", country: "ng", population: 250443 },
  { name: "Isieke", country: "ng", population: 89990 },
  { name: "Ijebu-Igbo", country: "ng", population: 109261 },
  { name: "Gwasoro", country: "ng", population: 7263 },
  { name: "Etinan", country: "ng", population: 10318 },
  { name: "Bakori", country: "ng", population: 12604 },
  { name: "Tipitapa", country: "ni", population: 50000 },
  { name: "Siuna", country: "ni", population: 16056 },
  { name: "Santa Teresa", country: "ni", population: 5789 },
  { name: "San Ramón", country: "ni", population: 2827 },
  { name: "Nindirí", country: "ni", population: 7073 },
  { name: "Masatepe", country: "ni", population: 21452 },
  { name: "El Sauce", country: "ni", population: 11898 },
  { name: "Camoapa", country: "ni", population: 16653 },
  { name: "Acoyapa", country: "ni", population: 10563 },
  { name: "Terrabona", country: "ni", population: 1902 },
  { name: "Puerto Cabezas", country: "ni", population: 33635 },
  { name: "Diriá", country: "ni", population: 3903 },
  { name: "Zuilichem", country: "nl", population: 1245 },
  { name: "Zoetermeer", country: "nl", population: 115845 },
  { name: "Zeelst", country: "nl", population: 5280 },
  { name: "Zeeland", country: "nl", population: 4750 },
  { name: "Wolder", country: "nl", population: 1425 },
  { name: "Waalwijk", country: "nl", population: 45610 },
  { name: "Vijlen", country: "nl", population: 1290 },
  { name: "Veltum", country: "nl", population: 3765 },
  { name: "Ulestraten", country: "nl", population: 2595 },
  { name: "Teteringen", country: "nl", population: 7230 },
  { name: "Terschuur", country: "nl", population: 1480 },
  { name: "Strijp", country: "nl", population: 3955 },
  { name: "Vinstra", country: "no", population: 2449 },
  { name: "Vikersund", country: "no", population: 2448 },
  { name: "Tynset", country: "no", population: 2356 },
  { name: "Skarnes", country: "no", population: 2172 },
  { name: "Sauda", country: "no", population: 4290 },
  { name: "Røyken", country: "no", population: 2775 },
  { name: "Hov", country: "no", population: 2095 },
  { name: "Brumunddal", country: "no", population: 8441 },
  { name: "Brekstad", country: "no", population: 1835 },
  { name: "Åneby", country: "no", population: 1373 },
  { name: "Ålesund", country: "no", population: 44096 },
  { name: "Vadsø", country: "no", population: 5139 },
  { name: "Pātan", country: "np", population: 183310 },
  { name: "Malaṅgawā", country: "np", population: 20284 },
  { name: "Bhojpur", country: "np", population: 2640 },
  { name: "Bāglung", country: "np", population: 23296 },
  { name: "Jumla", country: "np", population: 9073 },
  { name: "Dailekh", country: "np", population: 20908 },
  { name: "Birendranagar", country: "np", population: 31381 },
  { name: "Gaur", country: "np", population: 27325 },
  { name: "Birgañj", country: "np", population: 133238 },
  { name: "Gulariyā", country: "np", population: 53107 },
  { name: "Inaruwa", country: "np", population: 70093 },
  { name: "Dārchulā", country: "np", population: 18317 },
  { name: "Yaren", country: "nr", population: 1100 },
  { name: "Menen", country: "nr", population: 1400 },
  { name: "Arijejen", country: "nr", population: 2381 },
  { name: "Denigomodu", country: "nr", population: 1804 },
  { name: "Waiuku", country: "nz", population: 9168 },
  { name: "Ohinemutu", country: "nz", population: 1065 },
  { name: "Oakura", country: "nz", population: 1690 },
  { name: "Kelvin Grove", country: "nz", population: 7203 },
  { name: "Karori", country: "nz", population: 15660 },
  { name: "Fitzroy", country: "nz", population: 3504 },
  { name: "Fendalton", country: "nz", population: 4570 },
  { name: "Darfield", country: "nz", population: 2960 },
  { name: "Gore", country: "nz", population: 13050 },
  { name: "Red Hill", country: "nz", population: 2556 },
  { name: "Pegasus", country: "nz", population: 3810 },
  { name: "Weston", country: "nz", population: 1210 },
  { name: "Haymā’", country: "om", population: 1294 },
  { name: "Badīyah", country: "om", population: 18479 },
  { name: "Madḩā’ al Jadīdah", country: "om", population: 2260 },
  { name: "Khasab", country: "om", population: 17904 },
  { name: "Bidbid", country: "om", population: 21188 },
  { name: "Muscat", country: "om", population: 797000 },
  { name: "Izkī", country: "om", population: 36203 },
  { name: "Yanqul", country: "om", population: 16599 },
  { name: "Şalālah", country: "om", population: 163140 },
  { name: "Barkā’", country: "om", population: 81647 },
  { name: "Rustaq", country: "om", population: 79383 },
  { name: "Sur", country: "om", population: 71152 },
  { name: "Valle de Riscó", country: "pa", population: 1171 },
  { name: "Puerto Caimito", country: "pa", population: 4174 },
  { name: "Montijo", country: "pa", population: 1932 },
  { name: "Llano Largo", country: "pa", population: 1013 },
  { name: "La Colorada", country: "pa", population: 1214 },
  { name: "San Isidro", country: "pa", population: 1648 },
  { name: "Bajo Boquete", country: "pa", population: 2257 },
  { name: "Manaca Norte", country: "pa", population: 1294 },
  { name: "Santo Domingo", country: "pa", population: 1772 },
  { name: "San José", country: "pa", population: 1444 },
  { name: "San Ignacio de Tupile", country: "pa", population: 1429 },
  { name: "Pacora", country: "pa", population: 6856 },
  { name: "La Legua - San Jacinto", country: "pe", population: 7548 },
  { name: "Eten", country: "pe", population: 13693 },
  { name: "El Alto", country: "pe", population: 9303 },
  { name: "Bambamarca", country: "pe", population: 13351 },
  { name: "Yura", country: "pe", population: 5686 },
  { name: "Tupac Amaru", country: "pe", population: 11159 },
  { name: "Sandia", country: "pe", population: 3675 },
  { name: "Pampamarca", country: "pe", population: 3811 },
  { name: "Pacocha", country: "pe", population: 7588 },
  { name: "Hacienda Huancane", country: "pe", population: 7831 },
  { name: "Huacho", country: "pe", population: 54545 },
  { name: "Alto Salaverry", country: "pe", population: 5014 },
  { name: "Rikitea", country: "pf", population: 1103 },
  { name: "Hitiaa", country: "pf", population: 1826 },
  { name: "Vairao", country: "pf", population: 2423 },
  { name: "Tiarei", country: "pf", population: 2795 },
  { name: "Tautira", country: "pf", population: 2293 },
  { name: "Fare", country: "pf", population: 1540 },
  { name: "Otutara", country: "pf", population: 4609 },
  { name: "Teavaro", country: "pf", population: 2289 },
  { name: "Papao", country: "pf", population: 10360 },
  { name: "Haapiti", country: "pf", population: 3959 },
  { name: "Taunoa", country: "pf", population: 4131 },
  { name: "Papeete", country: "pf", population: 26357 },
  { name: "Tari", country: "pg", population: 8186 },
  { name: "Ambunti", country: "pg", population: 2073 },
  { name: "Mount Hagen", country: "pg", population: 33623 },
  { name: "Kokoda", country: "pg", population: 6199 },
  { name: "Wabag", country: "pg", population: 3958 },
  { name: "Kimbe", country: "pg", population: 18847 },
  { name: "Wau", country: "pg", population: 14629 },
  { name: "Rabaul", country: "pg", population: 8074 },
  { name: "Mendi", country: "pg", population: 26252 },
  { name: "Alotau", country: "pg", population: 10025 },
  { name: "Arawa", country: "pg", population: 40266 },
  { name: "Panguna", country: "pg", population: 2916 },
  { name: "Maglamin", country: "ph", population: 2633 },
  { name: "Lourdes", country: "ph", population: 3198 },
  { name: "Limbo", country: "ph", population: 4842 },
  { name: "Limbaan", country: "ph", population: 4855 },
  { name: "Leones East", country: "ph", population: 2713 },
  { name: "Larap", country: "ph", population: 4329 },
  { name: "Larap", country: "ph", population: 4113 },
  { name: "Lapolapo", country: "ph", population: 3621 },
  { name: "Tairan Camp", country: "ph", population: 2766 },
  { name: "Lanat", country: "ph", population: 2178 },
  { name: "Lanas", country: "ph", population: 2499 },
  { name: "Lamba", country: "ph", population: 6408 },
  { name: "Shahr Sultan", country: "pk", population: 16166 },
  { name: "Naushahro Firoz", country: "pk", population: 17631 },
  { name: "Narang Mandi", country: "pk", population: 34778 },
  { name: "Mailsi", country: "pk", population: 64545 },
  { name: "Kot Radha Kishan", country: "pk", population: 45938 },
  { name: "Kohlu", country: "pk", population: 11089 },
  { name: "Khuzdar", country: "pk", population: 141227 },
  { name: "Kallar Kahar", country: "pk", population: 15800 },
  { name: "Islamkot", country: "pk", population: 12426 },
  { name: "Hazro City", country: "pk", population: 26309 },
  { name: "Haveli Lakha", country: "pk", population: 65289 },
  { name: "Digri", country: "pk", population: 31842 },
  { name: "Żyraków", country: "pl", population: 1400 },
  { name: "Zwoleń", country: "pl", population: 8048 },
  { name: "Zamch", country: "pl", population: 1500 },
  { name: "Wojsławice", country: "pl", population: 1600 },
  { name: "Wawer", country: "pl", population: 77205 },
  { name: "Turze Pole", country: "pl", population: 1040 },
  { name: "Szczuczyn", country: "pl", population: 3552 },
  { name: "Suchedniów", country: "pl", population: 9184 },
  { name: "Staszów", country: "pl", population: 16137 },
  { name: "Sochaczew", country: "pl", population: 38267 },
  { name: "Odrzykoń", country: "pl", population: 2950 },
  { name: "Nowosielce-Gniewosz", country: "pl", population: 1200 },
  { name: "Miquelon", country: "pm", population: 1141 },
  { name: "Saint-Pierre", country: "pm", population: 6200 },
  { name: "Carolina", country: "pr", population: 170404 },
  { name: "Central Aguirre", country: "pr", population: 1588 },
  { name: "Luis Llorens Torres", country: "pr", population: 3328 },
  { name: "Monserrate", country: "pr", population: 2756 },
  { name: "Mora", country: "pr", population: 1857 },
  { name: "Salinas", country: "pr", population: 6375 },
  { name: "Bufalo", country: "pr", population: 1108 },
  { name: "Isabela", country: "pr", population: 12505 },
  { name: "Jauca", country: "pr", population: 1838 },
  { name: "El Ojo", country: "pr", population: 1713 },
  { name: "Guánica", country: "pr", population: 9224 },
  { name: "Luis M. Cintron", country: "pr", population: 2634 },
  { name: "‘Abasān al Kabīrah", country: "ps", population: 18163 },
  { name: "Shuyūkh al ‘Arrūb", country: "ps", population: 1527 },
  { name: "Kafr Qaddūm", country: "ps", population: 3376 },
  { name: "Ḩablah", country: "ps", population: 5945 },
  { name: "Dayr Dibwān", country: "ps", population: 6692 },
  { name: "Baytūnyā", country: "ps", population: 12822 },
  { name: "Bayt Imrīn", country: "ps", population: 2794 },
  { name: "‘Azmūţ", country: "ps", population: 2622 },
  { name: "Ar Rām wa Ḑāḩiyat al Barīd", country: "ps", population: 24838 },
  { name: "Jabālyā", country: "ps", population: 168568 },
  { name: "Za‘tarah", country: "ps", population: 6210 },
  { name: "Qalqīlyah", country: "ps", population: 43212 },
  { name: "Vila Nova da Barquinha", country: "pt", population: 7322 },
  { name: "Senhora da Luz", country: "pt", population: 3545 },
  { name: "Santo André", country: "pt", population: 11213 },
  { name: "Olivais", country: "pt", population: 33788 },
  { name: "Oleiros", country: "pt", population: 5721 },
  { name: "Nadadouro", country: "pt", population: 1904 },
  { name: "Meca", country: "pt", population: 1719 },
  { name: "Laranjeira", country: "pt", population: 20988 },
  { name: "Charneca", country: "pt", population: 10509 },
  { name: "Caparica", country: "pt", population: 21167 },
  { name: "Almada", country: "pt", population: 34008 },
  { name: "Rio Mau", country: "pt", population: 1407 },
  { name: "Koror", country: "pw", population: 14000 },
  { name: "Koror Town", country: "pw", population: 12676 },
  { name: "General Artigas", country: "py", population: 4073 },
  { name: "Carapeguá", country: "py", population: 5784 },
  { name: "Puerto Mayor Otaño", country: "py", population: 4701 },
  { name: "Nueva Italia", country: "py", population: 2841 },
  { name: "La Paloma del Espíritu Santo", country: "py", population: 4383 },
  { name: "Fernando de la Mora", country: "py", population: 120167 },
  { name: "Areguá", country: "py", population: 11222 },
  { name: "Filadelfia", country: "py", population: 8048 },
  { name: "Nueva Esperanza", country: "py", population: 12717 },
  { name: "Edelira", country: "py", population: 1698 },
  { name: "Ypacarai", country: "py", population: 9655 },
  { name: "Puerto Rosario", country: "py", population: 5123 },
  { name: "Ash Shīḩānīyah", country: "qa", population: 8380 },
  { name: "Al Ghuwayrīyah", country: "qa", population: 2332 },
  { name: "Al Wukayr", country: "qa", population: 5146 },
  { name: "Doha", country: "qa", population: 344939 },
  { name: "Al Khawr", country: "qa", population: 18923 },
  { name: "Madīnat ash Shamāl", country: "qa", population: 5267 },
  { name: "Umm Şalāl Muḩammad", country: "qa", population: 29391 },
  { name: "Ar Ruways", country: "qa", population: 3334 },
  { name: "Musay‘īd", country: "qa", population: 5769 },
  { name: "Umm Bāb", country: "qa", population: 2500 },
  { name: "Ar Rayyān", country: "qa", population: 272465 },
  { name: "Fuwayriţ", country: "qa", population: 1333 },
  { name: "Saint-Louis", country: "re", population: 47881 },
  { name: "Saint-Pierre", country: "re", population: 76655 },
  { name: "Salazie", country: "re", population: 7696 },
  { name: "Entre-Deux", country: "re", population: 5788 },
  { name: "La Possession", country: "re", population: 26865 },
  { name: "Sainte-Marie", country: "re", population: 31636 },
  { name: "Le Port", country: "re", population: 40771 },
  { name: "Bras-Panon", country: "re", population: 10444 },
  { name: "Saint-Joseph", country: "re", population: 33694 },
  { name: "Cilaos", country: "re", population: 6225 },
  { name: "Saint-André", country: "re", population: 48674 },
  { name: "Saint-Denis", country: "re", population: 137195 },
  { name: "Zorleni", country: "ro", population: 9511 },
  { name: "Vutcani", country: "ro", population: 2391 },
  { name: "Vlădila", country: "ro", population: 2198 },
  { name: "Victoria", country: "ro", population: 4305 },
  { name: "Valea Roșie", country: "ro", population: 2456 },
  { name: "Valea Arini", country: "ro", population: 1326 },
  { name: "Urlaţi", country: "ro", population: 11798 },
  { name: "Uricani", country: "ro", population: 9924 },
  { name: "Ulmi", country: "ro", population: 3899 },
  { name: "Turnu", country: "ro", population: 1164 },
  { name: "Tupilaţi", country: "ro", population: 2388 },
  { name: "Tritenii de Sus", country: "ro", population: 1272 },
  { name: "Sakule", country: "rs", population: 2280 },
  { name: "Šajkaš", country: "rs", population: 3541 },
  { name: "Lokve", country: "rs", population: 3511 },
  { name: "Hrtkovci", country: "rs", population: 2855 },
  { name: "Čokešina", country: "rs", population: 1262 },
  { name: "Lagja e Poshtme", country: "rs", population: 1200 },
  { name: "Vilovo", country: "rs", population: 1089 },
  { name: "Kruševac", country: "rs", population: 75256 },
  { name: "Ilandža", country: "rs", population: 2426 },
  { name: "Gudurica", country: "rs", population: 1448 },
  { name: "Gornji Milanovac", country: "rs", population: 23982 },
  { name: "Čoka", country: "rs", population: 5414 },
  { name: "Verkhniy Bekhtemir", country: "ru", population: 1200 },
  { name: "Uray", country: "ru", population: 39878 },
  { name: "Shipunovo", country: "ru", population: 11431 },
  { name: "Serov", country: "ru", population: 98438 },
  { name: "Sargazy", country: "ru", population: 1827 },
  { name: "Novoasbest", country: "ru", population: 2394 },
  { name: "Gal’bshtadt", country: "ru", population: 1778 },
  { name: "Nadym", country: "ru", population: 46339 },
  { name: "Monetnyy", country: "ru", population: 5540 },
  { name: "Mikhaylovskoye", country: "ru", population: 11467 },
  { name: "Mayna", country: "ru", population: 5287 },
  { name: "Lesnoye", country: "ru", population: 2000 },
  { name: "Kibungo", country: "rw", population: 46240 },
  { name: "Byumba", country: "rw", population: 70593 },
  { name: "Nzega", country: "rw", population: 33832 },
  { name: "Gisenyi", country: "rw", population: 136830 },
  { name: "Butare", country: "rw", population: 89600 },
  { name: "Kigali", country: "rw", population: 1132686 },
  { name: "Musanze", country: "rw", population: 86685 },
  { name: "Cyangugu", country: "rw", population: 63883 },
  { name: "Eglise Catholique, Centrale GIKO", country: "rw", population: 3000 },
  { name: "Kibuye", country: "rw", population: 48024 },
  { name: "Gitarama", country: "rw", population: 87613 },
  { name: "Rwamagana", country: "rw", population: 47203 },
  { name: "Sulţānah", country: "sa", population: 946697 },
  { name: "Al Munayzilah", country: "sa", population: 16296 },
  { name: "Al Jarādīyah", country: "sa", population: 7396 },
  { name: "Turaif", country: "sa", population: 40819 },
  { name: "Tārūt", country: "sa", population: 85371 },
  { name: "Mislīyah", country: "sa", population: 6117 },
  { name: "Julayjilah", country: "sa", population: 5359 },
  { name: "Qaisumah", country: "sa", population: 20685 },
  { name: "Tumayr", country: "sa", population: 8246 },
  { name: "At Tūbī", country: "sa", population: 7740 },
  { name: "Abqaiq", country: "sa", population: 29474 },
  { name: "Badr Ḩunayn", country: "sa", population: 27257 },
  { name: "Gizo", country: "sb", population: 6154 },
  { name: "Kirakira", country: "sb", population: 1122 },
  { name: "Auki", country: "sb", population: 6811 },
  { name: "Buala", country: "sb", population: 2700 },
  { name: "Tulagi", country: "sb", population: 1750 },
  { name: "Malango", country: "sb", population: 10532 },
  { name: "Honiara", country: "sb", population: 56298 },
  { name: "Victoria", country: "sc", population: 22881 },
  { name: "Bel Ombre", country: "sc", population: 4163 },
  { name: "Anse Boileau", country: "sc", population: 4183 },
  { name: "Anse Royale", country: "sc", population: 3818 },
  { name: "Beau Vallon", country: "sc", population: 4142 },
  { name: "Cascade", country: "sc", population: 4088 },
  { name: "Port Glaud", country: "sc", population: 2174 },
  { name: "Takamaka", country: "sc", population: 2580 },
  { name: "Ad-Damazin", country: "sd", population: 186051 },
  { name: "Abū Zabad", country: "sd", population: 15304 },
  { name: "Shendi", country: "sd", population: 53568 },
  { name: "Argo", country: "sd", population: 7716 },
  { name: "Al Fūlah", country: "sd", population: 11238 },
  { name: "Ed Damer", country: "sd", population: 103941 },
  { name: "Kuraymah", country: "sd", population: 19593 },
  { name: "Atbara", country: "sd", population: 107930 },
  { name: "Sinnar", country: "sd", population: 130122 },
  { name: "Dongola", country: "sd", population: 13473 },
  { name: "Ar Ruseris", country: "sd", population: 28862 },
  { name: "Al Hasaheisa", country: "sd", population: 28735 },
  { name: "Storvik", country: "se", population: 2251 },
  { name: "Pajala", country: "se", population: 2032 },
  { name: "Virserum", country: "se", population: 1769 },
  { name: "Vattholma", country: "se", population: 1410 },
  { name: "Tvååker", country: "se", population: 2640 },
  { name: "Tierp", country: "se", population: 5826 },
  { name: "Södra Vi", country: "se", population: 1134 },
  { name: "Skrea", country: "se", population: 1846 },
  { name: "Skinnskatteberg", country: "se", population: 2383 },
  { name: "Skara", country: "se", population: 11437 },
  { name: "Siljansnäs", country: "se", population: 1274 },
  { name: "Sandarne", country: "se", population: 1681 },
  { name: "Marine Parade", country: "sg", population: 46390 },
  { name: "Woodlands", country: "sg", population: 252530 },
  { name: "Štore", country: "si", population: 1937 },
  { name: "Razvanje", country: "si", population: 1357 },
  { name: "Muta", country: "si", population: 2300 },
  { name: "Mozirje", country: "si", population: 2085 },
  { name: "Maribor", country: "si", population: 95171 },
  { name: "Lovrenc na Pohorju", country: "si", population: 1977 },
  { name: "Krog", country: "si", population: 1132 },
  { name: "Izola", country: "si", population: 11223 },
  { name: "Zgornje Pirniče", country: "si", population: 1267 },
  { name: "Vipava", country: "si", population: 1771 },
  { name: "Ribnica", country: "si", population: 3604 },
  { name: "Ig", country: "si", population: 2262 },
  { name: "Longyearbyen", country: "sj", population: 2368 },
  { name: "Vyšné Ružbachy", country: "sk", population: 1296 },
  { name: "Sabinov", country: "sk", population: 12290 },
  { name: "Vrútky", country: "sk", population: 7762 },
  { name: "Oravský Podzámok", country: "sk", population: 1319 },
  { name: "Krasňany", country: "sk", population: 1231 },
  { name: "Čierny Balog", country: "sk", population: 5234 },
  { name: "Vrbov", country: "sk", population: 1455 },
  { name: "Michalovce", country: "sk", population: 40360 },
  { name: "Medzilaborce", country: "sk", population: 6541 },
  { name: "Vrbové", country: "sk", population: 5947 },
  { name: "Tvrdošín", country: "sk", population: 9544 },
  { name: "Smolenice", country: "sk", population: 3287 },
  { name: "Masingbi", country: "sl", population: 5644 },
  { name: "Mamboma", country: "sl", population: 5201 },
  { name: "Lunsar", country: "sl", population: 22461 },
  { name: "Hangha", country: "sl", population: 5007 },
  { name: "Gandorhun", country: "sl", population: 12288 },
  { name: "Daru", country: "sl", population: 5958 },
  { name: "Bumbuna", country: "sl", population: 3222 },
  { name: "Blama", country: "sl", population: 8146 },
  { name: "Gbewebu", country: "sl", population: 4122 },
  { name: "Kent", country: "sl", population: 1804 },
  { name: "Yengema", country: "sl", population: 13358 },
  { name: "Giehun", country: "sl", population: 3350 },
  { name: "Faetano", country: "sm", population: 1177 },
  { name: "Fiorentino", country: "sm", population: 2510 },
  { name: "Borgo Maggiore", country: "sm", population: 6424 },
  { name: "Acquaviva", country: "sm", population: 1530 },
  { name: "Domagnano", country: "sm", population: 3161 },
  { name: "San Marino", country: "sm", population: 4500 },
  { name: "Serravalle", country: "sm", population: 9258 },
  { name: "Tionk Essil", country: "sn", population: 8151 },
  { name: "Diofior", country: "sn", population: 8645 },
  { name: "Dara", country: "sn", population: 30000 },
  { name: "Tiadiaye", country: "sn", population: 10853 },
  { name: "Rosso", country: "sn", population: 9923 },
  { name: "Louga", country: "sn", population: 67154 },
  { name: "Tambacounda", country: "sn", population: 78800 },
  { name: "Warang", country: "sn", population: 3500 },
  { name: "Mbaké", country: "sn", population: 74100 },
  { name: "Thiès Nones", country: "sn", population: 252320 },
  { name: "Sédhiou", country: "sn", population: 19702 },
  { name: "Ndioum", country: "sn", population: 13198 },
  { name: "Gaalkacyo", country: "so", population: 61200 },
  { name: "Buurhakaba", country: "so", population: 27792 },
  { name: "Saacow", country: "so", population: 7893 },
  { name: "Kismayo", country: "so", population: 234852 },
  { name: "Dujuuma", country: "so", population: 2432 },
  { name: "Burao", country: "so", population: 99270 },
  { name: "Afgooye", country: "so", population: 65461 },
  { name: "Las Khorey", country: "so", population: 6941 },
  { name: "Jawhar", country: "so", population: 47086 },
  { name: "Jamaame", country: "so", population: 185270 },
  { name: "Ceelbuur", country: "so", population: 9031 },
  { name: "Garbahaarrey", country: "so", population: 12652 },
  { name: "Brokopondo", country: "sr", population: 14662 },
  { name: "Paramaribo", country: "sr", population: 223757 },
  { name: "Meerzorg", country: "sr", population: 12405 },
  { name: "Moengo", country: "sr", population: 7074 },
  { name: "Brownsweg", country: "sr", population: 2696 },
  { name: "Nieuw Nickerie", country: "sr", population: 13143 },
  { name: "Mariënburg", country: "sr", population: 4427 },
  { name: "Wageningen", country: "sr", population: 4145 },
  { name: "Nieuw Amsterdam", country: "sr", population: 4935 },
  { name: "Onverwacht", country: "sr", population: 2105 },
  { name: "Totness", country: "sr", population: 1685 },
  { name: "Lelydorp", country: "sr", population: 18223 },
  { name: "Tambura", country: "ss", population: 9500 },
  { name: "Kapoeta", country: "ss", population: 7042 },
  { name: "Yirol", country: "ss", population: 11650 },
  { name: "Kafia Kingi", country: "ss", population: 16000 },
  { name: "Yei", country: "ss", population: 260720 },
  { name: "Yambio", country: "ss", population: 40382 },
  { name: "Torit", country: "ss", population: 20048 },
  { name: "Kodok", country: "ss", population: 7709 },
  { name: "Gogrial", country: "ss", population: 44600 },
  { name: "Bentiu", country: "ss", population: 7653 },
  { name: "Wau", country: "ss", population: 127384 },
  { name: "Aweil", country: "ss", population: 38745 },
  { name: "São Tomé", country: "st", population: 53300 },
  { name: "Santo António", country: "st", population: 1156 },
  { name: "San Martín", country: "sv", population: 39361 },
  { name: "Santa Tecla", country: "sv", population: 124694 },
  { name: "El Congo", country: "sv", population: 8905 },
  { name: "Texistepeque", country: "sv", population: 2401 },
  { name: "San Miguel", country: "sv", population: 161880 },
  { name: "Pasaquina", country: "sv", population: 2149 },
  { name: "Intipucá", country: "sv", population: 3540 },
  { name: "El Rosario", country: "sv", population: 5620 },
  { name: "Chalchuapa", country: "sv", population: 32282 },
  { name: "Apopa", country: "sv", population: 112158 },
  { name: "Nueva Esparta", country: "sv", population: 2528 },
  { name: "Moncagua", country: "sv", population: 2148 },
  { name: "Philipsburg", country: "sx", population: 1400 },
  { name: "Lower Prince’s Quarter", country: "sx", population: 8000 },
  { name: "Cul de Sac", country: "sx", population: 8467 },
  { name: "Koolbaai", country: "sx", population: 6000 },
  { name: "Şāfītā", country: "sy", population: 27815 },
  { name: "Qārah", country: "sy", population: 20656 },
  { name: "Mukharram al Fawqānī", country: "sy", population: 9112 },
  { name: "Kafr Lāhā", country: "sy", population: 21819 },
  { name: "Jayrūd", country: "sy", population: 32352 },
  { name: "Al Qaryatayn", country: "sy", population: 31748 },
  { name: "Manbij", country: "sy", population: 99497 },
  { name: "‘Afrīn", country: "sy", population: 48693 },
  { name: "Ath Thawrah", country: "sy", population: 87880 },
  { name: "As Salamīyah", country: "sy", population: 94887 },
  { name: "Ma‘lūlā", country: "sy", population: 5000 },
  { name: "Dūmā", country: "sy", population: 111864 },
  { name: "Kubuta", country: "sz", population: 2038 },
  { name: "Tshaneni", country: "sz", population: 1899 },
  { name: "Manzini", country: "sz", population: 110537 },
  { name: "Mhlume", country: "sz", population: 8652 },
  { name: "Hluti", country: "sz", population: 6763 },
  { name: "Sidvokodvo", country: "sz", population: 1746 },
  { name: "Bhunya", country: "sz", population: 3046 },
  { name: "Mbabane", country: "sz", population: 76218 },
  { name: "Nsoko", country: "sz", population: 1175 },
  { name: "Hlatikulu", country: "sz", population: 2748 },
  { name: "Nhlangano", country: "sz", population: 9016 },
  { name: "Malkerns", country: "sz", population: 9724 },
  { name: "Cockburn Town", country: "tc", population: 3720 },
  { name: "Bitkine", country: "td", population: 18495 },
  { name: "Béré", country: "td", population: 14666 },
  { name: "Pala", country: "td", population: 35466 },
  { name: "Massakory", country: "td", population: 15406 },
  { name: "Oum Hadjer", country: "td", population: 19271 },
  { name: "Mongo", country: "td", population: 27763 },
  { name: "Béboto", country: "td", population: 5432 },
  { name: "Koumra", country: "td", population: 36263 },
  { name: "Bébédja", country: "td", population: 12671 },
  { name: "Iriba", country: "td", population: 1100 },
  { name: "Mao", country: "td", population: 18031 },
  { name: "Kyabé", country: "td", population: 16177 },
  { name: "Vogan", country: "tg", population: 20569 },
  { name: "Niamtougou", country: "tg", population: 23261 },
  { name: "Atakpamé", country: "tg", population: 80683 },
  { name: "Sokodé", country: "tg", population: 117811 },
  { name: "Sansanné-Mango", country: "tg", population: 37748 },
  { name: "Pagouda", country: "tg", population: 7686 },
  { name: "Bafilo", country: "tg", population: 22543 },
  { name: "Tabligbo", country: "tg", population: 13748 },
  { name: "Bassar", country: "tg", population: 61845 },
  { name: "Kpalimé", country: "tg", population: 75084 },
  { name: "Kara", country: "tg", population: 104207 },
  { name: "Dapaong", country: "tg", population: 33324 },
  { name: "Thoen", country: "th", population: 18459 },
  { name: "Phuket", country: "th", population: 89072 },
  { name: "Fang", country: "th", population: 7635 },
  { name: "Cha-am", country: "th", population: 49375 },
  { name: "Sung Noen", country: "th", population: 14365 },
  { name: "Pattani", country: "th", population: 43690 },
  { name: "Nong Chik", country: "th", population: 11385 },
  { name: "Nang Rong", country: "th", population: 21961 },
  { name: "Nakhon Phanom", country: "th", population: 34595 },
  { name: "Ban Sam Chuk", country: "th", population: 13438 },
  { name: "Ban Phaeo", country: "th", population: 41904 },
  { name: "Bamnet Narong", country: "th", population: 14890 },
  { name: "Norak", country: "tj", population: 18122 },
  { name: "Murghob", country: "tj", population: 10815 },
  { name: "Dŭstí", country: "tj", population: 8700 },
  { name: "Konibodom", country: "tj", population: 50359 },
  { name: "Jilikŭl", country: "tj", population: 5434 },
  { name: "Kim", country: "tj", population: 1600 },
  { name: "Hisor", country: "tj", population: 23978 },
  { name: "Obigarm", country: "tj", population: 7400 },
  { name: "Yovon", country: "tj", population: 17471 },
  { name: "Sovet", country: "tj", population: 8695 },
  { name: "Roghun", country: "tj", population: 7731 },
  { name: "Panjakent", country: "tj", population: 35085 },
  { name: "Viqueque", country: "tl", population: 6078 },
  { name: "Manatutu", country: "tl", population: 1924 },
  { name: "Lospalos", country: "tl", population: 17186 },
  { name: "Pante Makasar", country: "tl", population: 4730 },
  { name: "Aileu", country: "tl", population: 17356 },
  { name: "Dili", country: "tl", population: 150000 },
  { name: "Gleno", country: "tl", population: 8133 },
  { name: "Same", country: "tl", population: 7500 },
  { name: "Ainaro", country: "tl", population: 12000 },
  { name: "Venilale", country: "tl", population: 16000 },
  { name: "Metinaro", country: "tl", population: 4000 },
  { name: "Maubara", country: "tl", population: 16300 },
  { name: "Serdar", country: "tm", population: 12000 },
  { name: "Arçabil", country: "tm", population: 3000 },
  { name: "Boldumsaz", country: "tm", population: 21159 },
  { name: "Türkmenabat", country: "tm", population: 234817 },
  { name: "Murgab", country: "tm", population: 13199 },
  { name: "Balkanabat", country: "tm", population: 87822 },
  { name: "Ashgabat", country: "tm", population: 727700 },
  { name: "Yylanly", country: "tm", population: 26901 },
  { name: "Tagta", country: "tm", population: 16635 },
  { name: "Akdepe", country: "tm", population: 14177 },
  { name: "Türkmenbaşy", country: "tm", population: 68292 },
  { name: "Serhetabat", country: "tm", population: 5200 },
  { name: "Sidi Bou Rouis", country: "tn", population: 3265 },
  { name: "Kélibia", country: "tn", population: 58524 },
  { name: "El Fahs", country: "tn", population: 23561 },
  { name: "Banbalah", country: "tn", population: 12367 },
  { name: "Skhira", country: "tn", population: 11944 },
  { name: "Tabarka", country: "tn", population: 13712 },
  { name: "Midoun", country: "tn", population: 32000 },
  { name: "Mennzel Bou Zelfa", country: "tn", population: 18551 },
  { name: "Medjez el Bab", country: "tn", population: 20118 },
  { name: "Ar Rudayyif", country: "tn", population: 26976 },
  { name: "Ezzouhour", country: "tn", population: 17348 },
  { name: "Tamaghzah", country: "tn", population: 2750 },
  { name: "Neiafu", country: "to", population: 4320 },
  { name: "Pangai", country: "to", population: 1738 },
  { name: "Nuku‘alofa", country: "to", population: 22400 },
  { name: "‘Ohonua", country: "to", population: 1241 },
  { name: "Kolonga", country: "to", population: 1100 },
  { name: "Haveluloto", country: "to", population: 3417 },
  { name: "Tatakamotonga", country: "to", population: 3000 },
  { name: "Vaini", country: "to", population: 2976 },
  { name: "Lapaha", country: "to", population: 8000 },
  { name: "Yeşilalan", country: "tr", population: 3791 },
  { name: "Sulak", country: "tr", population: 1433 },
  { name: "Sivrice", country: "tr", population: 1467 },
  { name: "Saruhanlı", country: "tr", population: 12947 },
  { name: "Ortaköy", country: "tr", population: 7486 },
  { name: "Mersin", country: "tr", population: 537842 },
  { name: "Malazgirt", country: "tr", population: 26108 },
  { name: "Lice", country: "tr", population: 16336 },
  { name: "Kemah", country: "tr", population: 2549 },
  { name: "Kazancı", country: "tr", population: 1401 },
  { name: "Karalar", country: "tr", population: 4718 },
  { name: "Karaburun", country: "tr", population: 3249 },
  { name: "Debe", country: "tt", population: 3127 },
  { name: "Couva", country: "tt", population: 5178 },
  { name: "Chaguanas", country: "tt", population: 67433 },
  { name: "Petit Valley", country: "tt", population: 8140 },
  { name: "Princes Town", country: "tt", population: 10000 },
  { name: "Mon Repos", country: "tt", population: 56380 },
  { name: "Port of Spain", country: "tt", population: 49031 },
  { name: "Rio Claro", country: "tt", population: 35650 },
  { name: "Arima", country: "tt", population: 35000 },
  { name: "Marabella", country: "tt", population: 26700 },
  { name: "Arouca", country: "tt", population: 12054 },
  { name: "Sangre Grande", country: "tt", population: 15968 },
  { name: "Funafuti", country: "tv", population: 4492 },
  { name: "Fakaifou Village", country: "tv", population: 1007 },
  { name: "Alapi Village", country: "tv", population: 1024 },
  { name: "Urambo", country: "tz", population: 23724 },
  { name: "Tumbi", country: "tz", population: 32900 },
  { name: "Rulenge", country: "tz", population: 22069 },
  { name: "Nyanguge", country: "tz", population: 10746 },
  { name: "Naberera", country: "tz", population: 13499 },
  { name: "Mwanza", country: "tz", population: 436801 },
  { name: "Mwandiga", country: "tz", population: 16822 },
  { name: "Maneromango", country: "tz", population: 9402 },
  { name: "Katoro", country: "tz", population: 49691 },
  { name: "Katesh", country: "tz", population: 4943 },
  { name: "Igugunu", country: "tz", population: 22810 },
  { name: "Chimala", country: "tz", population: 24817 },
  { name: "Volochysk", country: "ua", population: 18802 },
  { name: "Staryy Vovchynets", country: "ua", population: 2039 },
  { name: "Verkhovyna", country: "ua", population: 5829 },
  { name: "Verkhnesadovoye", country: "ua", population: 2331 },
  { name: "Velyka Mykhaylivka", country: "ua", population: 5458 },
  { name: "Vatutine", country: "ua", population: 16096 },
  { name: "Udobne", country: "ua", population: 1947 },
  { name: "Tysovets", country: "ua", population: 3556 },
  { name: "Tsybulivka", country: "ua", population: 1888 },
  { name: "Teplohirsk", country: "ua", population: 9343 },
  { name: "Stalnivtsi", country: "ua", population: 2104 },
  { name: "Spaske", country: "ua", population: 3017 },
  { name: "Moroto", country: "ug", population: 10300 },
  { name: "Masindi Port", country: "ug", population: 7828 },
  { name: "Kanungu", country: "ug", population: 14600 },
  { name: "Wakiso", country: "ug", population: 20530 },
  { name: "Nebbi", country: "ug", population: 30354 },
  { name: "Njeru", country: "ug", population: 61952 },
  { name: "Masindi", country: "ug", population: 31486 },
  { name: "Arua", country: "ug", population: 55585 },
  { name: "Amudat", country: "ug", population: 1873 },
  { name: "Lyantonde", country: "ug", population: 8039 },
  { name: "Ibanda", country: "ug", population: 31000 },
  { name: "Fort Portal", country: "ug", population: 42670 },
  { name: "Fairfield", country: "us", population: 42767 },
  { name: "Kings Mills", country: "us", population: 1319 },
  { name: "Mount Healthy Heights", country: "us", population: 3264 },
  { name: "Pleasant Run", country: "us", population: 4953 },
  { name: "Powhatan Point", country: "us", population: 1573 },
  { name: "Woodlawn", country: "us", population: 3292 },
  { name: "Geary", country: "us", population: 1305 },
  { name: "Newkirk", country: "us", population: 2253 },
  { name: "Haymarket", country: "us", population: 1980 },
  { name: "Montrose", country: "us", population: 7993 },
  { name: "University Center", country: "us", population: 3586 },
  { name: "Vienna", country: "us", population: 16522 },
  { name: "Soca", country: "uy", population: 1742 },
  { name: "Rafael Perazza", country: "uy", population: 1274 },
  { name: "Ombúes de Lavalle", country: "uy", population: 3390 },
  { name: "Juanicó", country: "uy", population: 1305 },
  { name: "Puntas de Valdéz", country: "uy", population: 1491 },
  { name: "Pajas Blancas", country: "uy", population: 2000 },
  { name: "Villa del Carmen", country: "uy", population: 2703 },
  { name: "25 de Mayo", country: "uy", population: 1852 },
  { name: "Tomás Gomensoro", country: "uy", population: 2872 },
  { name: "Piriápolis", country: "uy", population: 7968 },
  { name: "Piedras Coloradas", country: "uy", population: 1094 },
  { name: "Montevideo", country: "uy", population: 1270737 },
  { name: "Urgut Shahri", country: "uz", population: 47373 },
  { name: "Bukhara", country: "uz", population: 247644 },
  { name: "Uchqŭrghon Shahri", country: "uz", population: 33323 },
  { name: "Nurota", country: "uz", population: 29403 },
  { name: "Muborak", country: "uz", population: 29180 },
  { name: "Koson", country: "uz", population: 59681 },
  { name: "Chiroqchi", country: "uz", population: 19356 },
  { name: "Beshkent", country: "uz", population: 17010 },
  { name: "Olot Shahri", country: "uz", population: 11984 },
  { name: "Vobkent Shahri", country: "uz", population: 16697 },
  { name: "Qiziltepa", country: "uz", population: 13558 },
  { name: "Qŭnghirot", country: "uz", population: 80090 },
  { name: "Biabou", country: "vc", population: 1050 },
  { name: "Kingstown", country: "vc", population: 24518 },
  { name: "Georgetown", country: "vc", population: 1680 },
  { name: "Byera Village", country: "vc", population: 1365 },
  { name: "Kingstown Park", country: "vc", population: 17994 },
  { name: "Ureña", country: "ve", population: 56509 },
  { name: "Santa Ana", country: "ve", population: 19215 },
  { name: "San Rafael", country: "ve", population: 55810 },
  { name: "Mucuchíes", country: "ve", population: 8880 },
  { name: "Mene de Mauroa", country: "ve", population: 19174 },
  { name: "Guanarito", country: "ve", population: 37219 },
  { name: "Charallave", country: "ve", population: 129182 },
  { name: "Los Puertos de Altagracia", country: "ve", population: 66839 },
  { name: "Valencia", country: "ve", population: 1619470 },
  { name: "San Pedro de Coche", country: "ve", population: 6704 },
  { name: "San Juan Bautista", country: "ve", population: 90387 },
  { name: "Sanare", country: "ve", population: 35850 },
  { name: "Road Town", country: "vg", population: 8449 },
  { name: "Saint Croix", country: "vi", population: 50601 },
  { name: "Cruz Bay", country: "vi", population: 2743 },
  { name: "Charlotte Amalie", country: "vi", population: 20000 },
  { name: "Vị Thanh", country: "vn", population: 24477 },
  { name: "Tây Ninh", country: "vn", population: 36456 },
  { name: "Tam Kỳ", country: "vn", population: 59000 },
  { name: "Quảng Ngãi", country: "vn", population: 32992 },
  { name: "Lào Cai", country: "vn", population: 36502 },
  { name: "Dĩ An", country: "vn", population: 474681 },
  { name: "Cờ Đỏ", country: "vn", population: 116576 },
  { name: "Cẩm Phả Mines", country: "vn", population: 135477 },
  { name: "Ea Drăng", country: "vn", population: 20600 },
  { name: "Thái Bình", country: "vn", population: 53071 },
  { name: "Nha Trang", country: "vn", population: 283441 },
  { name: "Nhà Bè", country: "vn", population: 17264 },
  { name: "Sola", country: "vu", population: 1171 },
  { name: "Port-Olry", country: "vu", population: 1951 },
  { name: "Luganville", country: "vu", population: 13397 },
  { name: "Norsup", country: "vu", population: 2998 },
  { name: "Isangel", country: "vu", population: 1437 },
  { name: "Port-Vila", country: "vu", population: 35901 },
  { name: "Mata-Utu", country: "wf", population: 1200 },
  { name: "Vailima", country: "ws", population: 1462 },
  { name: "Lufilufi", country: "ws", population: 1074 },
  { name: "Nofoali‘i", country: "ws", population: 1828 },
  { name: "Gataivai", country: "ws", population: 1141 },
  { name: "Malie", country: "ws", population: 2189 },
  { name: "Leulumoega", country: "ws", population: 1458 },
  { name: "Afega", country: "ws", population: 1895 },
  { name: "Vaiusu", country: "ws", population: 2043 },
  { name: "Solosolo", country: "ws", population: 1487 },
  { name: "Siusega", country: "ws", population: 2226 },
  { name: "Satapuala", country: "ws", population: 1412 },
  { name: "Faleula", country: "ws", population: 2592 },
  { name: "Srbica", country: "xk", population: 5089 },
  { name: "Partesh", country: "xk", population: 1787 },
  { name: "Llazicë", country: "xk", population: 19863 },
  { name: "Novo Brdo", country: "xk", population: 6720 },
  { name: "Shtime", country: "xk", population: 35000 },
  { name: "Podujeva", country: "xk", population: 35000 },
  { name: "Ferizaj", country: "xk", population: 59504 },
  { name: "Strellc i Epërm", country: "xk", population: 6100 },
  { name: "Gjinoc", country: "xk", population: 1571 },
  { name: "Lloqan", country: "xk", population: 1050 },
  { name: "Kosovo Polje", country: "xk", population: 16154 },
  { name: "Istok", country: "xk", population: 40000 },
  { name: "At Taḩāluf", country: "ye", population: 1281 },
  { name: "Al-Medy Village, قرية المدي", country: "ye", population: 2000 },
  { name: "Kilmia", country: "ye", population: 2013 },
  { name: "Jawf al Maqbābah", country: "ye", population: 14175 },
  { name: "Aden", country: "ye", population: 550602 },
  { name: "Zabīd", country: "ye", population: 52590 },
  { name: "Saḩar", country: "ye", population: 31859 },
  { name: "Aḑ Ḑāli‘", country: "ye", population: 14760 },
  { name: "Al Madakisha", country: "ye", population: 5000 },
  { name: "‘Amrān", country: "ye", population: 90792 },
  { name: "Dhī as Sufāl", country: "ye", population: 37997 },
  { name: "Al Qabyal", country: "ye", population: 2000 },
  { name: "Chiconi", country: "yt", population: 7204 },
  { name: "Bandraboua", country: "yt", population: 7880 },
  { name: "Sada", country: "yt", population: 9832 },
  { name: "Mamoudzou", country: "yt", population: 54831 },
  { name: "Ouangani", country: "yt", population: 7272 },
  { name: "Dzaoudzi", country: "yt", population: 15339 },
  { name: "Kani Kéli", country: "yt", population: 4854 },
  { name: "Acoua", country: "yt", population: 4622 },
  { name: "Koungou", country: "yt", population: 18118 },
  { name: "Chirongui", country: "yt", population: 6173 },
  { name: "Tsingoni", country: "yt", population: 7602 },
  { name: "M'Tsangamouji", country: "yt", population: 5028 },
  { name: "Mthatha", country: "za", population: 78663 },
  { name: "Howick", country: "za", population: 28725 },
  { name: "Greytown", country: "za", population: 13912 },
  { name: "Graaff-Reinet", country: "za", population: 62896 },
  { name: "Diepsloot", country: "za", population: 350000 },
  { name: "Orkney", country: "za", population: 13435 },
  { name: "Mossel Bay", country: "za", population: 59031 },
  { name: "Montagu", country: "za", population: 15176 },
  { name: "Middelburg", country: "za", population: 154706 },
  { name: "Krugersdorp", country: "za", population: 378821 },
  { name: "Calitzdorp", country: "za", population: 4284 },
  { name: "Barberton", country: "za", population: 67927 },
  { name: "Samfya", country: "zm", population: 20470 },
  { name: "Mpika", country: "zm", population: 28445 },
  { name: "Luanshya", country: "zm", population: 113365 },
  { name: "Chongwe", country: "zm", population: 6057 },
  { name: "Chililabombwe", country: "zm", population: 57328 },
  { name: "Kasama", country: "zm", population: 91056 },
  { name: "Mufumbwe", country: "zm", population: 6155 },
  { name: "Siavonga", country: "zm", population: 18638 },
  { name: "Chinsali", country: "zm", population: 14015 },
  { name: "Chingola", country: "zm", population: 148564 },
  { name: "Lusaka", country: "zm", population: 1267440 },
  { name: "Lukulu", country: "zm", population: 3349 },
  { name: "Lalapanzi", country: "zw", population: 1390 },
  { name: "Beatrice", country: "zw", population: 1647 },
  { name: "Victoria Falls", country: "zw", population: 35761 },
  { name: "Shurugwi", country: "zw", population: 17075 },
  { name: "Ruwa", country: "zw", population: 30000 },
  { name: "Lupane", country: "zw", population: 1200 },
  { name: "Hwange", country: "zw", population: 33210 },
  { name: "Odzi", country: "zw", population: 3438 },
  { name: "Gwanda", country: "zw", population: 14450 },
  { name: "Chipinge", country: "zw", population: 18860 },
  { name: "Macheke", country: "zw", population: 3642 },
  { name: "Kamativi Mine", country: "zw", population: 1575 },
];

export const countriesWithImage = countries.filter((c) =>
  countryCodesWithImage.includes(c.code.toLowerCase())
);

export const smallCountryLimit = 5000;
export const bigEnoughCountriesWithImage = countriesWithImage.filter(
  (country) => areas[country.code] > smallCountryLimit
);

export function getCountryName(language: string, country: Country) {
  switch (language) {
    case "fr":
      return frenchCountryNames[country.code];
    case "hu":
      return hungarianCountryNames[country.code];
    case "nl":
      return dutchCountryNames[country.code];
    case "pl":
      return polishCountryNames[country.code];
    case "jp":
      return japaneseCountryNames[country.code];
    default:
      return country.name;
  }
}

export function sanitizeCountryName(countryName: string): string {
  return countryName
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/[- '()]/g, "")
    .toLowerCase();
}
