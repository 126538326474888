import { Guesses } from "../Guesses";
import { Panel } from "./Panel";
import React from "react";
import { Worldle } from "../Worldle";
import { formatDistance } from "../../domain/geography";
import { SettingsData } from "../../hooks/useSettings";
import { Twemoji } from "react-emoji-render";

interface InfosProps {
  isOpen: boolean;
  close: () => void;
  settingsData: SettingsData;
}

export function InfosFr({ isOpen, close, settingsData }: InfosProps) {
  return (
    <Panel title="Comment jouer" isOpen={isOpen} close={close}>
      <div className="space-y-3 text-justify border-b-2 border-gray-200 pb-3 mb-3">
        <div>
          {" "}
          <Worldle />
          &nbsp;: devinez l&apos;endroit où se trouve la ville du jour en 6
          essais.
        </div>
        <div>Chaque essai doit être un pays, un territoire, etc... valide.</div>
        <div>
          Après chaque essai, vous aurez la distance, la direction et la
          proximité entre votre essai et le pays cible.
        </div>
      </div>
      <div className="space-y-3 text-justify border-b-2 border-gray-200 pb-3 mb-3">
        <div className="font-bold">Exemples</div>
        <div>
          <Guesses
            rowCount={1}
            guesses={[
              {
                name: "Chili",
                direction: "NE",
                distance: 13_557_000,
              },
            ]}
            settingsData={settingsData}
          />
          <div className="my-2">
            Votre essai <span className="uppercase font-bold">Chili</span> est à{" "}
            {formatDistance(13_557_000, settingsData.distanceUnit)} du pays
            cible, le pays cible se trouve dans la direction Nord-Est et vous
            avez une proximité de seulement 32% car votre essai est plutôt
            éloigné !
          </div>
        </div>
        <div>
          <Guesses
            rowCount={1}
            guesses={[
              {
                name: "Finlande",
                direction: "SE",
                distance: 3_206_000,
              },
            ]}
            settingsData={settingsData}
          />
          <div className="my-2">
            Votre second essai{" "}
            <span className="uppercase font-bold">Finlande</span> est plus
            proche ! La bonne réponse est à{" "}
            {formatDistance(3_206_000, settingsData.distanceUnit)}, au Sud-Est
            et la proximité est de 84%!
          </div>
        </div>
        <div>
          <Guesses
            rowCount={1}
            guesses={[
              {
                name: "Liban",
                direction: "N",
                distance: 0,
              },
            ]}
            settingsData={settingsData}
          />
          <div className="my-2">
            Prochain essai, <span className="uppercase font-bold">Liban</span>,
            c&apos;est le pays à deviner ! Bien joué !{" "}
            <Twemoji text="🎉" options={{ className: "inline-block" }} />
          </div>
        </div>
      </div>
      <div className="space-y-3 text-justify border-b-2 border-gray-200 pb-3 mb-3 font-bold">
        Un nouveau <Worldle /> sera disponible chaque jour !
      </div>
      <div className="space-y-3 text-justify border-b-2 border-gray-200 pb-3 mb-3">
        <div className="font-bold">Une question ?</div>
        <div>
          Quelques pistes sur la{" "}
          <a
            className="underline"
            href="https://worldle.notion.site/Worldle-4005ca16fc9341e7be8adcfd30628146"
            target="_blank"
            rel="noopener noreferrer"
          >
            FAQ Worldle
          </a>{" "}
          (Babelle en soi n&apos;a pas de FAQ, écrivez à Terry Laire)
        </div>
      </div>
      <div className="space-y-3 text-justify border-b-2 border-gray-200 pb-3 mb-3">
        <Worldle /> est une pure et simple copie de{" "}
        <a
          className="underline"
          href="https://worldle.teuteuf.fr/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Wor<span className="text-green-600">l</span>dle
        </a>{" "}
        créé par{" "}
        <a
          className="underline"
          href="https://twitter.com/teuteuf"
          target="_blank"
          rel="noopener noreferrer"
        >
          Teuteuf (@teuteuf)
        </a>{" "}
        sous licence MIT.
      </div>
      <div className="space-y-3 text-justify border-b-2 border-gray-200 pb-3 mb-3">
        Worldle a été <span className="font-bold">très</span> inspiré par{" "}
        <a
          className="underline"
          href="https://www.powerlanguage.co.uk/wordle/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Wordle
        </a>{" "}
        créé par{" "}
        <a
          className="underline"
          href="https://twitter.com/powerlanguish"
          target="_blank"
          rel="noopener noreferrer"
        >
          Josh Wardle (@powerlanguish)
        </a>
        .
      </div>
      <div className="space-y-3 text-justify pb-3">
        <div>
          Mis en place par{" "}
          <a
            className="underline"
            href="https://terrylaire.fr"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terry Laire
          </a>{" "}
          - (
          <a
            className="underline"
            href="https://gitlab.com/terrylaire/babelle/"
            target="_blank"
            rel="noopener noreferrer"
          >
            code source
          </a>
          )
        </div>
        <div>
          Idée et nommage par{" "}
          <a
            className="underline"
            href="https://laurapsikorski.fr/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Laura P. Sikorski
          </a>{" "}
        </div>
        <div>
          Liste des villes sous licence Creative commons BY{" "}
          <a
            className="underline"
            href="https://www.geonames.org/about.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.geonames.org/about.html
          </a>{" "}
        </div>
      </div>
    </Panel>
  );
}
