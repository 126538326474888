export const areas: Record<string, number> = {
  AW: 193,
  AF: 652090,
  AO: 1246700,
  AI: 96,
  AL: 28748,
  AD: 468,
  AN: 800,
  AE: 83600,
  AR: 2780400,
  AM: 29800,
  AS: 199,
  AQ: 13120000,
  TF: 7780,
  AG: 442,
  AU: 7741220,
  AT: 83859,
  AZ: 86600,
  BI: 27834,
  BE: 30518,
  BJ: 112622,
  BF: 274000,
  BD: 143998,
  BG: 110994,
  BH: 694,
  BS: 13878,
  BA: 51197,
  BY: 207600,
  BZ: 22696,
  BM: 53,
  BO: 1098581,
  BR: 8547403,
  BB: 430,
  BN: 5765,
  BT: 47000,
  BV: 59,
  BW: 581730,
  CF: 622984,
  CA: 9970610,
  CC: 14,
  CH: 41284,
  CL: 756626,
  CN: 9572900,
  CI: 322463,
  CM: 475442,
  CD: 2344858,
  CG: 342000,
  CK: 236,
  CO: 1138914,
  KM: 1862,
  CV: 4033,
  CR: 51100,
  CU: 110861,
  CX: 135,
  KY: 264,
  CY: 9251,
  CZ: 78866,
  DE: 357022,
  DJ: 23200,
  DM: 751,
  DK: 43094,
  DO: 48511,
  DZ: 2381741,
  EC: 283561,
  EG: 1001449,
  ER: 117600,
  EH: 266000,
  ES: 505992,
  EE: 45227,
  ET: 1104300,
  FI: 338145,
  FJ: 18274,
  FK: 12173,
  FR: 551500,
  FO: 1399,
  FM: 702,
  GA: 267668,
  GB: 242900,
  GE: 69700,
  GH: 238533,
  GI: 6,
  GN: 245857,
  GP: 1705,
  GM: 11295,
  GW: 36125,
  GQ: 28051,
  GR: 131626,
  GD: 344,
  GL: 2166090,
  GT: 108889,
  GF: 90000,
  GU: 549,
  GY: 214969,
  HM: 359,
  HN: 112088,
  HR: 56538,
  HT: 27750,
  HU: 93030,
  ID: 1904569,
  IN: 3287263,
  IO: 78,
  IE: 70273,
  IR: 1648195,
  IQ: 438317,
  IS: 103000,
  IL: 21056,
  IT: 301316,
  JM: 10990,
  JO: 88946,
  JP: 377829,
  KZ: 2724900,
  KE: 580367,
  KG: 199900,
  KH: 181035,
  KI: 726,
  KN: 261,
  KR: 99434,
  KW: 17818,
  LA: 236800,
  LB: 10400,
  LR: 111369,
  LY: 1759540,
  LC: 622,
  LI: 160,
  LK: 65610,
  LS: 30355,
  LT: 65301,
  LU: 2586,
  LV: 64589,
  MA: 446550,
  MC: 1.5,
  MD: 33851,
  MG: 587041,
  MV: 298,
  MX: 1958201,
  MH: 181,
  MK: 25713,
  ML: 1240192,
  MT: 316,
  MM: 676578,
  MN: 1566500,
  MP: 464,
  MZ: 801590,
  MR: 1025520,
  MS: 102,
  MQ: 1102,
  MU: 2040,
  MW: 118484,
  MY: 329758,
  YT: 373,
  NA: 824292,
  NC: 18575,
  NE: 1267000,
  NF: 36,
  NG: 923768,
  NI: 130000,
  NU: 260,
  NL: 41526,
  NO: 323877,
  NP: 147181,
  NR: 21,
  NZ: 270534,
  OM: 309500,
  PK: 796095,
  PA: 75517,
  PN: 49,
  PE: 1285216,
  PH: 300000,
  PW: 459,
  PG: 462840,
  PL: 323250,
  PR: 8875,
  KP: 120538,
  PT: 91982,
  PY: 406752,
  PS: 6257,
  PF: 4000,
  QA: 11000,
  RE: 2510,
  RO: 238391,
  RU: 17075400,
  RW: 26338,
  SA: 2149690,
  SD: 1886068,
  SS: 619745,
  SN: 196722,
  RS: 88361,
  GS: 3903,
  SH: 314,
  SJ: 62422,
  SB: 28896,
  SL: 71740,
  SV: 21041,
  SM: 61,
  SO: 637657,
  PM: 242,
  ST: 964,
  SR: 163265,
  SK: 49012,
  SI: 20256,
  SE: 449964,
  SZ: 17364,
  SC: 455,
  SY: 185180,
  TC: 430,
  TD: 1284000,
  TG: 56785,
  TH: 513115,
  TJ: 143100,
  TK: 12,
  TM: 488100,
  TP: 14874,
  TO: 650,
  TT: 5130,
  TN: 163610,
  TR: 774815,
  TV: 26,
  TW: 36188,
  TZ: 883749,
  UG: 241038,
  UA: 603700,
  UM: 16,
  UY: 175016,
  US: 9363520,
  UZ: 447400,
  VA: 0.4,
  VC: 388,
  VE: 912050,
  VG: 151,
  VI: 347,
  VN: 331689,
  VU: 12189,
  WF: 200,
  WS: 2831,
  YE: 527968,
  ZA: 1221037,
  ZM: 752618,
  ZW: 390757,
  GG: 78,
  GZ: 365,
  IM: 572,
  JE: 119.6,
  ME: 13812,
  TL: 15007,
  XK: 10887,
};
