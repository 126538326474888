export const countryCodesWithImage = [
  "rw",
  "sn",
  "gh",
  "ni",
  "af",
  "pm",
  "iq",
  "nc",
  "gg",
  "cf",
  "tm",
  "ai",
  "cy",
  "ki",
  "ge",
  "mt",
  "pe",
  "nz",
  "bo",
  "ua",
  "sk",
  "mq",
  "al",
  "gm",
  "tj",
  "bh",
  "mz",
  "bd",
  "ml",
  "bi",
  "bw",
  "sb",
  "pa",
  "gy",
  "ug",
  "lk",
  "lv",
  "jo",
  "mx",
  "sm",
  "me",
  "gf",
  "bs",
  "ss",
  "tg",
  "mu",
  "zw",
  "km",
  "ye",
  "by",
  "mc",
  "gu",
  "rs",
  "lc",
  "tv",
  "aq",
  "sd",
  "lb",
  "bn",
  "mm",
  "be",
  "pw",
  "bm",
  "kp",
  "ae",
  "ci",
  "vg",
  "tl",
  "lr",
  "de",
  "sy",
  "gb",
  "ba",
  "cz",
  "au",
  "th",
  "es",
  "sl",
  "dm",
  "ly",
  "co",
  "in",
  "dk",
  "mw",
  "ls",
  "do",
  "tr",
  "dj",
  "kn",
  "uz",
  "gt",
  "mk",
  "mf",
  "nl",
  "az",
  "cn",
  "ro",
  "cu",
  "uy",
  "cg",
  "mh",
  "dz",
  "sv",
  "ao",
  "pk",
  "cd",
  "ar",
  "pt",
  "fo",
  "no",
  "aw",
  "eg",
  "cw",
  "ck",
  "bj",
  "kh",
  "vi",
  "sj",
  "mn",
  "ga",
  "bl",
  "vn",
  "ve",
  "nr",
  "yt",
  "tn",
  "ht",
  "hn",
  "qa",
  "bf",
  "cr",
  "vc",
  "sx",
  "je",
  "la",
  "gw",
  "om",
  "hr",
  "as",
  "cl",
  "eh",
  "td",
  "gd",
  "bg",
  "ax",
  "ws",
  "se",
  "pr",
  "kw",
  "lu",
  "sr",
  "ee",
  "br",
  "pf",
  "il",
  "jm",
  "mr",
  "kz",
  "bt",
  "ag",
  "bz",
  "ky",
  "cv",
  "np",
  "kr",
  "si",
  "ec",
  "jp",
  "vu",
  "xk",
  "ir",
  "sc",
  "ca",
  "id",
  "mp",
  "fj",
  "tz",
  "ng",
  "kg",
  "ke",
  "mg",
  "tt",
  "us",
  "zm",
  "re",
  "hu",
  "im",
  "at",
  "pl",
  "st",
  "fr",
  "bq",
  "so",
  "et",
  "to",
  "gi",
  "tc",
  "ph",
  "md",
  "pg",
  "gn",
  "gp",
  "is",
  "li",
  "za",
  "ch",
  "er",
  "sa",
  "my",
  "ie",
  "mv",
  "tw",
  "ps",
  "fi",
  "sz",
  "lt",
  "ru",
  "bb",
  "it",
  "ma",
  "wf",
  "gr",
  "fk",
  "ad",
  "cm",
  "gl",
  "ne",
  "na",
  "fm",
  "am",
  "py",
  "gq",
];
